/* eslint-disable react/prop-types */
import React, { useState } from 'react'
import { Button, CircularProgress, Popover } from '@mui/material'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import './style.scss'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { afterMissionValidate, updateMission } from '_redux/Campaigns/reducers'
import API from 'services/axios-config'
import { enqueueSnackbar } from 'notistack'

const InfosCandidate = (props) => {
  const { candidate } = props
  const [anchorEl, setAnchorEl] = useState(null)
  const [disabledClick, setDisabledClick] = useState(false)
  const { color } = styleMission()
  const open = Boolean(anchorEl)
  const id = open ? 'simple-popover' : undefined
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [searchParams] = useSearchParams()
  const campaignId = Number(searchParams.get('campaign_id'))
  const prestationId = Number(searchParams.get('prestation_id'))
  const slotId = Number(searchParams.get('slot_id'))

  function styleMission () {
    let state
    let color
    if (['En attente de validation'].includes(candidate?.status)) {
      color = '#F3B922'
      state = 'En attente'
    } else if (['Refusé'].includes(candidate?.status)) {
      color = '#FF0000'
      state = 'Refusé'
    } else {
      color = '#FF0000'
      state = 'Non défini'
    }
    return { state, color }
  }

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleSlotChange = (state) => {
    setDisabledClick(true)
    API.patch(`/missions/${candidate?.id}`, { id: candidate?.id, status: state.status })
      .then(res => {
        dispatch(updateMission({ campaignId, prestationId, slotId, missionId: candidate?.id, updatedMission: { ...res?.data } }))
        if (res?.data?.status === 'Validé') {
          dispatch(afterMissionValidate({ campaignId, prestationId, slotId, missionId: candidate?.id }))
        }
      })
      .catch(err => {
        enqueueSnackbar('Patch mission : ' + err, { variant: 'error' })
      })
      .finally(() => {
        handleClose()
        setDisabledClick(false)
      })
  }

  return (
    <div className='infos-candidate-container'>
      <div style={{ position: "absolute", marginBottom: 50, fontSize: 8 }}>{candidate.id}</div>
      <div onClick={() => navigate(`/admin/street-marketeurs?marketer_id=${candidate.user_id}`)} style={{ display: "inline", cursor: "pointer" }}>{candidate.user_name}</div>
      <Button aria-describedby={id} variant="contained" onClick={handleClick} style={{ color, background: color + '50' }} className='candidate-state-container'>
        <div className='container-flex'>
          <div>
            {disabledClick && <div><CircularProgress style={{ position: 'absolute', width: '30px', height: '30px', top: '15%', left: '40%' }} /></div>}
            <span style={{ background: color }} className='candidate-state-dot' ></span>
            <div style={{ filter: disabledClick ? 'blur(4px)' : '', display: 'inline' }}>{candidate.status}</div>
          </div>
          <ArrowDropDownIcon />
        </div>
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
      >
        <div className='popover-candidate-container'>
          {
            ['Terminée', 'En cours', 'Validé', 'En attente de lancement', 'En attente de confirmation', 'En attente de validation', 'Refusé'].map((state, index) => {
              return <div
                key={index}
                className='popover-item'
                style={{ color: state === candidate.status ? color : 'black' }}
                onClick={() => handleSlotChange({ status: state })}
              >
                {state}
              </div>
            })
          }
        </div>
      </Popover>
    </div>
  )
}

export default InfosCandidate
