import { useState } from "react";
import { useSearchParams } from "react-router-dom";

const LeftComponent = (props) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [inputValue, setInputValue] = useState(
    searchParams.get("search") ? searchParams.get("search") : ""
  );
  const [typingTimeout, setTypingTimeout] = useState(null);

  const handleInputChange = (event) => {
    const value = event.target.value;
    setInputValue(value);
    if (typingTimeout) {
      clearTimeout(typingTimeout);
    }
    const newTimeout = setTimeout(() => {
      const params = new URLSearchParams(searchParams);
      if (params.has("search")) {
        params.set("search", value);
      } else {
        params.append("search", value);
      }
      setSearchParams(params);
    }, 500);

    setTypingTimeout(newTimeout);
  };

  return (
    <div className="bookmark-list">
      <h1 className="bookmark-title">{props.title}</h1>
      <div className="bookmark-filter">
        <input
          value={inputValue}
          onChange={handleInputChange}
          type="text"
          placeholder="Rechercher..."
          className="temporary-filter"
        />
      </div>
    </div>
  );
};

export default LeftComponent;
