/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import InfosSlot from './InfosSlot'
import { ReactComponent as PoubelleLogo } from 'assets/poubelle.svg'
import './style.scss'
import API from 'services/axios-config'
import AlertDialog from 'components/DialogBox'
import { enqueueSnackbar } from 'notistack'
import { removeSlot, updateMission, afterMissionValidate } from '_redux/Campaigns/reducers'
import MissionView from './MissionView'
import { useRightModal } from 'views/Lists/Campaigns/rightModalContext'
import { useSearchParams } from 'react-router-dom'

const ComponentSlotDetails = (props) => {
  const [searchParams] = useSearchParams()
  const campaignId = Number(searchParams.get('campaign_id'))
  const prestationId = Number(searchParams.get('prestation_id'))
  const slotId = Number(searchParams.get('slot_id'))

  const rawList = useSelector(state => state?.campaignsList?.list)
  const [currentSlot, setCurrentSlot] = useState([])
  const [updateModal, setUpdateModal] = useState(false)
  const { removedLastStack } = useRightModal()
  const dispatch = useDispatch()
  const [, setSearchParams] = useSearchParams()

  useEffect(() => {
    const selectedSlot = rawList?.filter(campaign => campaign.campaign.id === campaignId)[0]?.campaign.prestations?.filter(prestation_ => prestation_.id === prestationId)[0]?.slots.filter(slot_ => slot_.id === slotId)[0]
    setCurrentSlot(selectedSlot)
  }, [rawList])

  const handleDeleteSlot = () => {
    API.delete(`slots/${slotId}`)
      .then(() => {
        setSearchParams(prevParams => {
          const newParams = new URLSearchParams(prevParams);
          newParams.delete('slot_id');
          newParams.delete('prestation_id');
          return newParams;
        });
        removedLastStack()
        dispatch(removeSlot({ campaignId, prestationId, slotId }))
        setUpdateModal(false)
      })
      .catch(err => enqueueSnackbar(err))
  }

  return (
    <div className='component-slot-details'>
      <InfosSlot slot={currentSlot} />
      {currentSlot && <MissionView {...{ currentSlot }}/>}
      <div className='delete-slot'>
        <div className='delete-slot-button' onClick={() => setUpdateModal(true)}>
          <PoubelleLogo />
          SUPPRIMER LE CRÉNEAU
        </div>
      </div>
      <AlertDialog open={updateModal} dialogType="removeSlot" setOpen={setUpdateModal} action1={() => handleDeleteSlot()} />
    </div>
  )
}

export default ComponentSlotDetails
