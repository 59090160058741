import React from 'react'
import LeftContent from './LeftContent'
import RightContent from './RightContent'
import './style.scss'

const TopPageContent = () => {
  return (
    <div className="top-page-container">
      <LeftContent />
      <RightContent />
    </div>
  )
}

export default TopPageContent
