import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import SlotView from '../Slots'
import DraggableItem from './draggableItem'
import { useCampaignMap } from 'views/Cartes/CampaignViewMap/campaignMapContext'
import ZoneView from '../Zones'
import { useDispatch, useSelector } from 'react-redux'
import { dragStatus } from '_redux/Map/reducers'
import InitialView from '../Initials'
import { getItemType, needResetDrag, onDragEnd } from './function'
import { NewSlotBtn } from '../Slots/newSlotBtn'
import { useEffect, useState } from 'react'
import SpotView from '../Spots'
import MissionView from '../Mission'
import AnomalieView from '../Anomalies'
import AnomalieMissionModal from './anomalieMissionModal'

const SlotContainer = (props) => {
  const [slotIsVisible, setSlotIsVisible] = useState(true)
  const [slotOpen, setSlotOpen] = useState(true)
  const { currentSlot, prestaIsActive } = props

  useEffect(() => {
    setSlotIsVisible(prestaIsActive)
  }, [prestaIsActive])

  return (
    <SlotView {...props} {...{ currentSlot, prestaIsActive, slotIsVisible, setSlotIsVisible, slotOpen, setSlotOpen, ...props }}>
      {currentSlot?.items?.map((item, i) => {
        const itemType = getItemType(item)
        return <DraggableItem draggableId={`${itemType}-${item.id}-${currentSlot.id}`} index={i} key={`current-slot-${currentSlot?.id}-${itemType}-${item.id}-${i}`}>
          {(item.client_visible || !props.clientView) && <div className="test-children">
            {itemType === "zone" && <ZoneView currentZone={item} {...props} />}
            {itemType === "spot" && <SpotView currentSpot={item} {...props} />}
            {itemType === "mission" && <MissionView currentMission={item} {...props} />}
            {/* AJOUTER ICI LES AUTRES TYPES */}
          </div>}
        </DraggableItem>
      })}
    </SlotView>
  )
}

const InitialContainer = (props) => {
  return (<InitialView prestation={props.currentPresta}>
    {props?.currentInitials?.map((item, i) => {
      const itemType = getItemType(item)
      return <DraggableItem draggableId={`${itemType}-${item.id}`} index={i} key={`current-initials-${itemType}-${item.id}`}>
        {itemType === "zone" && <ZoneView currentZone={item} />}
        {itemType === "spot" && <SpotView currentSpot={item} />}
        {/* AJOUTER ICI LES AUTRES TYPES */}
      </DraggableItem>
    })}
  </InitialView>)
}

const AnomaliesContainer = (props) => {
  return (<AnomalieView prestation={props.currentPresta}>
    {props?.currentPresta?.anomalies?.map((item, i) => {
      const itemType = getItemType(item)
      return <DraggableItem draggableId={`${itemType}-${item.id}`} index={i} key={`current-initials-${itemType}-${item.id}`}>
        {itemType === "spot" && <SpotView currentSpot={item} />}
      </DraggableItem>
    })}
  </AnomalieView>)
}

const DraggableList = (props) => {
  const dispatch = useDispatch()
  const itemsDragSelected = useSelector((state) => state.map.itemsDragSelected)
  const { prestationsList, setPrestationsList, importAnomaliesCSV, importedAnomalies, openMissionAnomalieModel, setOpenMissionAnomalieModel } = useCampaignMap()
  const [anomaliesVisible, setAnomaliesVisible] = useState(false)

  return (<DragDropContext
    onDragStart={(provided) => {
      needResetDrag(provided.draggableId, props.currentPresta.id, prestationsList, itemsDragSelected, dispatch) // l'item drag fait t-il partie de la liste d'item séléctionné ?
      dispatch(dragStatus(true))
    }}
    onDragEnd={(result, provided) => {
      dispatch(dragStatus(false))
      onDragEnd(result, props.currentPresta.id, setPrestationsList, itemsDragSelected, dispatch)
    }}
  >
    <div style={{ backgroundColor: "white" }}>
      <Droppable droppableId="slots-droppable" type="SLOT">
        {(provided) => (
          <div {...provided.droppableProps} ref={provided.innerRef}>
            {props?.currentSlots?.map((slot, index) => (
              <Draggable key={slot?.id} draggableId={`slot-${slot?.id}`} index={index}>
                {(provided) => (
                  <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                  >
                    <SlotContainer {...props} currentSlot={slot} />
                  </div>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
      {!props.currentView && <NewSlotBtn prestation={props.currentPresta} />}
    </div>
    {props.currentPresta.presta_type === 'Flying Box' &&
      props.currentPresta.anomalies?.length &&
      !anomaliesVisible
      ? (<div style={{ backgroundColor: "white", marginTop: 10, padding: 5, color: 'black', justifyContent: 'center', borderColor: 'black', display: 'flex', cursor: 'pointer' }}
        onClick={() => setAnomaliesVisible(true)}>Afficher les anomalies</div>)
      : null}
    {anomaliesVisible && <AnomaliesContainer {...props} />}
    {props.currentPresta.presta_type === 'Flying Box' && (
      <div style={{ backgroundColor: "white", marginTop: 10, padding: 5, color: 'black', justifyContent: 'center', borderColor: 'black', display: 'flex', cursor: 'pointer' }}>
        <label style={{ cursor: 'pointer' }}>
          Importer des anomalies
          <input
            type="file"
            accept=".csv"
            style={{ display: "none" }}
            onChange={(e) => {
              importAnomaliesCSV(e, props.currentPresta.id);
              e.target.value = "";
            }} />
        </label>
      </div>
    )}
    <AnomalieMissionModal action={() => true} open={openMissionAnomalieModel} close={() => { setOpenMissionAnomalieModel(false) }} anomalies={importedAnomalies} prestationsList={prestationsList} setPrestationsList={setPrestationsList} prestaId={props.currentPresta.id} />
    <InitialContainer {...props} />
  </DragDropContext>)
}

export default DraggableList
