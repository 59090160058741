/* eslint-disable camelcase */
import React, { useState } from 'react'
import pen from 'assets/pen.png'
import moment from 'moment'
import { ReactComponent as MapLogo } from 'assets/global_map.svg'
import { ReactComponent as AssignerSMLogo } from 'assets/assignerSM.svg'
import './style.scss'
import ComponentSlotModification from '../../ComponentSlotModification'
import { BlackBtn } from 'views/Admin/Shared/Forms/BlackBtn'
import API from 'services/axios-config'
import { enqueueSnackbar } from 'notistack'
import { formatSecondsToTime } from 'utils/helpers'
import { useDispatch } from 'react-redux'
import { addMission, afterMissionValidate } from '_redux/Campaigns/reducers'
import { useSearchParams } from 'react-router-dom'
import { useRightModal } from 'views/Lists/Campaigns/rightModalContext'
import AssignSmModal from './assignationModal'

const InfosSlot = ({ slot }) => {
  const [modalAssignOpen, setModalAssignOpen] = useState(false)
  const [searchParams, setSearchParams] = useSearchParams()
  const [loading, setLoading] = useState(false)
  const dispatch = useDispatch()
  const { addToStack, removedLastStack } = useRightModal()
  const [isBackup, setIsBackup] = useState(false)
  const campaignId = Number(searchParams.get('campaign_id'))
  const prestationId = Number(searchParams.get('prestation_id'))

  function handleAssignSm(user_id) {
    setLoading(true)
    API.post(`users/${user_id}/slots/${modalAssignOpen}/missions?include_user=true`, { status: 'Validé', is_backup: isBackup })
      .then(res => {
        dispatch(addMission({ campaignId, prestationId, slotId: slot.id, addMission: res.data }))
        dispatch(afterMissionValidate({ campaignId, prestationId, slotId: slot.id, missionId: res.data.id }))
        setModalAssignOpen(false)
        setIsBackup(false)
      })
      .catch(err => enqueueSnackbar("Une erreur est survenu lors de l'assignation du SM" + err, { variant: 'error' }))
      .finally(_ => setLoading(false))
  }

  return (
    <div className='slot-details'>
      <div className='slot-details_title'>
        <div className='slot-details_title-firstContent'>Informations du créneau :
          <div style={{ color: slot?.title ? '' : '#FF0000', display: 'inline-block', marginLeft: '10px' }}>{slot?.title ? slot?.title : 'Sans titre'}</div>
        </div>
        <div className='slot-details_title-secondContent'>
          <div style={{ color: slot?.active ? '#07C025' : '#FF0000', background: slot?.active ? '#07C02550' : '#FF000050' }} className='slot-state'>
            <span style={{ background: slot?.active ? '#07C025' : '#FF0000' }} className='slot-state-dot' ></span>
            {slot?.active ? 'ACTIF' : 'INACTIF'}
          </div>
          <img src={pen} alt='Pen Image' className='slot-pen' onClick={() => addToStack({ component: <ComponentSlotModification slot={slot} type="update" /> })} />
        </div>
      </div>
      <div className='slot-details_content'>
        <div>Tarif:</div>
        {slot?.backup_cost
          ? (
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div>{slot?.cost ? slot?.cost : "À définir"}€ Par heure</div>
              <div style={{ display: "flex", gap: "18px" }}>
                {" "}
                <div>Bonus Backup : </div>
                <div> {slot?.backup_cost ? slot?.backup_cost : "À définir"}€</div>
              </div>
            </div>
          )
          : (
            <div>{slot?.cost ? slot?.cost : "À définir"}€ Par heure</div>
          )
        }
        <div>Date de début:</div>
        <div>{slot?.date_start_mission ? moment(slot?.date_start_mission).format('dddd D MMMM YYYY - HH[h]mm') : 'À définir'}</div>
        <div>Date de fin:</div>
        <div>{slot?.date_end_mission ? moment(slot?.date_end_mission).format('dddd D MMMM YYYY - HH[h]mm') : 'À définir'}</div>
        {slot?.total_time > 0 &&
  <>
    <div>Temps total :</div>
    <div>{formatSecondsToTime(slot?.total_time) }</div>
    <div>Temps de pause :</div>
    <div>{formatSecondsToTime(slot.duration_pause) }</div>
    <div>Total sans pause :</div>
    <div>{formatSecondsToTime(slot?.total_time - slot.duration_pause)}</div>
  </>
        }
        <div>Adresse:</div>
        <div>{slot?.address?.label ? slot?.address?.label : 'À définir'}</div>
      </div>
      <div className='slot-details_logo'>
        <BlackBtn
          label="CARTE GLOBALE"
          icon={<MapLogo style={{ width: 20, height: 20 }} fill="white" />}
          styles={{ width: 'auto' }}
          handleAction={() => {
            setSearchParams(prevParams => {
            const newParams = new URLSearchParams(prevParams);
            newParams.set('maps', true);
            return newParams;
            })
            removedLastStack()
          }}
        />
        <BlackBtn label="ASSIGNER UN SM" icon={<AssignerSMLogo style={{ width: 20, height: 20 }} fill="white" />} styles={{ width: 'auto' }} handleAction={() => setModalAssignOpen(slot.id)} />
        <AssignSmModal action={handleAssignSm} open={!!modalAssignOpen} close={() => { setModalAssignOpen(false); setIsBackup(false) }} loading={loading} />
      </div>
    </div>
  )
}

export default InfosSlot
