/* eslint-disable react/prop-types */
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import './style.scss'
import TopContent from './TopContent'
import ModifyContent from './ModifyContent'
import { BlackBtn } from 'views/Admin/Shared/Forms/BlackBtn'
import API from 'services/axios-config'
import { useRightModal } from 'views/Lists/Campaigns/rightModalContext'
import { modifyCampaignInfo, modifyCompanyInfo } from '_redux/Campaigns/reducers'
import AlertDialog from 'components/DialogBox'
import { DevisSideModal } from '../ComponentCampaignModification/TopContent/VoirDevisModal'
import { useSearchParams } from 'react-router-dom'

const ComponentCampaignModification = () => {
  const [searchParams] = useSearchParams()
  const campaignId = searchParams.get('campaign_id')
  const campaignRawList = useSelector(state => state.campaignsList.list.filter(item => item?.campaign?.id === Number(campaignId))[0]?.campaign)
  const dispatch = useDispatch()
  const [isLoading, setIsLoading] = useState(false)
  const { replaceLastStackAction, removedLastStack } = useRightModal()
  const [currentCampaign, setCurrentCampaign] = useState(null)
  const [alertOpen, setAlertOpen] = useState(false)
  const [isDevisOpen, setIsDevisOpen] = useState(false);

  const handleModifyCampaign = () => {
    setIsLoading(true)
    API.patch(`campaigns/${campaignRawList?.id}?include_company=true`, { ...currentCampaign?.campaign })
      .then(res => {
        dispatch(modifyCampaignInfo({ id: res?.data?.id, campaignData: res?.data }))
        dispatch(modifyCompanyInfo({ id: res?.data?.id, company: res?.data?.company }))
      })
      .finally(() => {
        removedLastStack()
        setIsLoading(false)
      })
  }

  function campaignHasChange () {
    setAlertOpen(true)
  }

  function handleChangeCampaign (value, field) {
    setCurrentCampaign((prev) => {
      const updatedPrev = { ...prev }
      if (!(field in updatedPrev)) {
        updatedPrev[field] = {}
      }
      updatedPrev[field] = { ...updatedPrev[field], ...value }
      return updatedPrev
    })
    replaceLastStackAction(campaignHasChange)
  }

  return (
    <div className="new-campaign-wrapper">
      <div className='component-campaign-modification'>
      <AlertDialog
        open={alertOpen}
        setOpen={setAlertOpen}
        dialogType="abortCampaignModify"
        action1={removedLastStack}
      />
        <TopContent {...{ handleChangeCampaign }} campaignData={{ campaign: { ...campaignRawList, ...currentCampaign?.campaign }, company: { ...campaignRawList?.company, ...currentCampaign?.company } }} toggleShowDevis={() => setIsDevisOpen(prev => !prev)} isDevisOpen={isDevisOpen} />
        <ModifyContent {...{ handleChangeCampaign }} campaignData={{ campaign: { ...campaignRawList, ...currentCampaign?.campaign }, company: { ...campaignRawList?.company, ...currentCampaign?.company } }} />
        <div className='save-campaign'>
          <div className='save-campaign-button'>
            <BlackBtn disabled={isLoading} label='ENREGISTRER' handleAction={handleModifyCampaign} />
          </div>
        </div>

      </div>
      {isDevisOpen && <DevisSideModal devis={campaignRawList.devis} company={campaignRawList.company} />}
    </div>
  )
}

export default ComponentCampaignModification
