/* eslint-disable */
import { OverlayView } from "@react-google-maps/api";
import { useCampaignMap } from "../../campaignMapContext";
import React, { useState, useEffect } from "react";
import SquareRoundedIcon from "@mui/icons-material/SquareRounded";
import FormatColorFillRoundedIcon from "@mui/icons-material/FormatColorFillRounded";
import CropSquareRoundedIcon from "@mui/icons-material/CropSquareRounded";
import { CompactPicker } from "react-color";
import "./style.scss";
import { parse } from "wellknown";
import {
  calculateArea,
  calculateLength,
  wktToPath,
} from "views/Admin/Views/Cartes/functions/functionsMap";
import CancelPresentationRoundedIcon from "@mui/icons-material/CancelPresentationRounded";
import { getItemType } from "../LeftLayout/DraggableList/function";
import API from "services/axios-config";
import { Checkbox, Divider } from "@mui/material";
import { InputChangeName, SelectChangeName } from "views/Cartes/CampaignViewMap/MapLayout/LeftLayout/components/ItemChangeTitle/index.js";
import { SelectAddressComponent } from 'components/SelectAddressComponent'
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import { ReactComponent as Star2Svg } from "assets/star2.svg";
import { formatHoursToHHMM } from "utils/helpers";

const ModalContainer = ({ children, setInfosModal, infoModalStyle }) => {
  const {
    anomaliesInfosModal,
    setAnomaliesInfosModal,
    prestationsList,
    infosModal
  } = useCampaignMap();

  useEffect(() => {
    if (anomaliesInfosModal && anomaliesInfosModal.length === 0) setAnomaliesInfosModal(null)
  }, [anomaliesInfosModal]);

  const infoModalPrestationType = React.useMemo(() => {
    let prestaType = '';
    if (infosModal && infosModal.slot_id) {
      const prestation = prestationsList.find(prestation =>
        prestation.slots.some(slot => slot.id === infosModal.slot_id)
      );
      if (prestation && prestation?.presta_type) prestaType = prestation.presta_type;
    }
    return prestaType
  }, [infosModal, prestationsList]);

  const smsNames = infosModal?.sms_names?.length ? infosModal?.sms_names.join(" / ") : "-";
  const formattedTotalHoursWithoutPause = infosModal?.total_hours_without_pause ? formatHoursToHHMM(infosModal?.total_hours_without_pause) : '-';
  const flyersPerHour = Math.round((infosModal?.total_hours_without_pause > 0 ? infosModal.total_distributed_flyers / infosModal.total_hours_without_pause : 0));


  return (
    <div
      className="infos-modal-container"
      style={infoModalStyle}
      ref={(ref) =>
        ref && window.google.maps.OverlayView.preventMapHitsAndGesturesFrom(ref)
      }
    >
      {infoModalPrestationType === "Flying Box" && 
        <div className="flying-box-zone-infowindow-container">
          <div className="flying-box-zone-title-conatiner">Informations de la zone et cadence</div>
          <div className="flying-box-zone-key-value-conatiner">
            <div className="zone-key">Nom de la zone :</div>
            <div className="zone-value">
              {infosModal?.title ?? "-"}
            </div>
          </div>
          <div className="flying-box-zone-key-value-conatiner">
            <div className="zone-key">Nom du SM :</div>
            <div className="zone-value">
              {smsNames}
            </div>
          </div>
          <div className="flying-box-zone-key-value-conatiner">
            <div className="zone-key">Total heures sans pause :</div>
            <div className="zone-value">
              {formattedTotalHoursWithoutPause}
            </div>
          </div>
          <div className="flying-box-zone-key-value-conatiner">
            <div className="zone-key">Nombre total de flyers distribués :</div>
            <div className="zone-value">
              {infosModal?.total_distributed_flyers ?? "-"}
            </div>
          </div>
          <div className="flying-box-zone-key-value-conatiner" style={{ fontWeight: 700 }}>
            <div className="zone-key">Nombre de flyers par heure :</div>
            <div className="zone-value">
              {flyersPerHour}
            </div>
          </div>
        </div>}
      <div className="close-btn" onClick={() => {
        setInfosModal(null)
        setAnomaliesInfosModal(null)
        }}>
        <CancelPresentationRoundedIcon />
        Fermer
      </div>
      {children}
    </div>
  );
};

const PolygonInfos = () => {
  const { infosModal, setInfosModal, setPrestationsList } = useCampaignMap();
  const [pickColor, setPickColor] = useState(false);

  const handleModifLayer = (infoChange) => {
    setPrestationsList((prev) =>
      prev.map((presta) => {
        if (presta.id === infosModal.prestation_id) {
          if (infosModal.slot_id) {
            return {
              ...presta,
              slots: presta.slots.map((slot) => {
                if (slot.id === infosModal.slot_id) {
                  return {
                    ...slot,
                    items: slot.items.map((item) => {
                      if (
                        item.id === infosModal.id &&
                        getItemType(infosModal) === getItemType(item)
                      ) {
                        setInfosModal({ ...item, ...infoChange });
                        return { ...item, ...infoChange };
                      } else {
                        return item;
                      }
                    }),
                  };
                } else {
                  return slot;
                }
              }),
            };
          } else {
            return {
              ...presta,
              initials: presta.initials.map((initial) => {
                if (
                  initial.id === infosModal.id &&
                  getItemType(infosModal) === getItemType(initial)
                ) {
                  setInfosModal({ ...initial, ...infoChange });
                  return { ...initial, ...infoChange };
                } else {
                  return initial;
                }
              }),
            };
          }
        } else {
          return presta;
        }
      })
    );
  };

  const currentCoordinates = wktToPath(
    parse(infosModal.polygon)?.coordinates[0]
  );

  const sendModifyToBackend = (params) => {
    API.patch(`${getItemType(infosModal) + "s"}/${infosModal.id}`, {
      ...params,
    });
  };
  return (
    <ModalContainer setInfosModal={setInfosModal}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginTop: 5,
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div className="display-value">
            <SquareRoundedIcon /> {calculateArea(currentCoordinates)} km²
          </div>
          <div className="display-value">
            <CropSquareRoundedIcon /> {calculateLength(currentCoordinates)} km
          </div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginLeft: 100,
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              padding: "0 5px",
              cursor: "pointer",
            }}
            onClick={() => setPickColor((prev) => !prev)}
          >
            <FormatColorFillRoundedIcon />
          </div>
        </div>
      </div>
      {pickColor && (
        <div style={{ display: "flex", flexDirection: "column", padding: 10 }}>
          <CompactPicker
            onChangeComplete={(e) => {
              handleModifLayer({ fillColor: e?.hex });
              sendModifyToBackend({ fillColor: e?.hex });
            }}
          />
          <p>Polygon transparency</p>
          <input
            type={"range"}
            max={100}
            min={2}
            defaultValue={infosModal.fillOpacity * 100 || 50}
            onChange={(e) =>
              handleModifLayer({ fillOpacity: e?.target?.value / 100 })
            }
            onMouseUp={(e) =>
              sendModifyToBackend({ fillOpacity: e?.target?.value / 100 })
            }
          />
          <p>Border width</p>
          <input
            type={"range"}
            max={30}
            min={1}
            defaultValue={infosModal.strokeWeight || 1}
            onChange={(e) =>
              handleModifLayer({ strokeWeight: e?.target?.value })
            }
            onMouseUp={(e) =>
              sendModifyToBackend({ strokeWeight: e?.target?.value })
            }
          />
        </div>
      )}
    </ModalContainer>
  );
};

const MarkerInfos = () => {
  const [spotsTypesList, setSpotsTypesList] = useState([]);
  const { infosModal, setInfosModal, setPrestationsList, modifyItems, prestationsList } =
    useCampaignMap();
  const [modifyFields, setModifyFields] = useState({});

  const [pickColor, setPickColor] = useState(false);
  const [rayonDisabled, setRayonDisabled] = useState(
    !infosModal.geofence_radius
  );

  useEffect(() => {
    API.get('/spots/get_spot_types')
    .then((res) => {
        if (res?.data?.spot_types) {
            setSpotsTypesList(res.data.spot_types)
        }
    })
  }, [])

  const handleChangeRayonVisible = (value) => {
    if (value?.target.checked) {
      setRayonDisabled(false);
    } else {
      setRayonDisabled(true);
      handleModifLayer({ geofence_radius: 0, radius: 0 });
      sendModifyToBackend({ geofence_radius: 0, radius: 0 });
    }
  };

  const handleModifLayer = (infoChange) => {
    setPrestationsList((prev) =>
      prev.map((presta) => {
        if (presta.id === infosModal.prestation_id) {
          if (infosModal.slot_id) {
            return {
              ...presta,
              slots: presta.slots.map((slot) => {
                if (slot.id === infosModal.slot_id) {
                  return {
                    ...slot,
                    items: slot.items.map((item) => {
                      if (
                        item.id === infosModal.id &&
                        getItemType(infosModal) === getItemType(item)
                      ) {
                        setInfosModal({ ...item, ...infoChange });
                        return { ...item, ...infoChange };
                      } else {
                        return item;
                      }
                    }),
                  };
                } else {
                  return slot;
                }
              }),
            };
          } else {
            return {
              ...presta,
              initials: presta.initials.map((initial) => {
                if (
                  initial.id === infosModal.id &&
                  getItemType(infosModal) === getItemType(initial)
                ) {
                  setInfosModal({ ...initial, ...infoChange });
                  return { ...initial, ...infoChange };
                } else {
                  return initial;
                }
              }),
            };
          }
        } else {
          return presta;
        }
      })
    );
  };

  const sendModifyToBackend = (params) => {
    API.patch(`${getItemType(infosModal) + "s"}/${infosModal.id}`, {
      ...params,
    });
  };

  function handleSendNewField(key, value) {
    API.patch(`spots/${infosModal.id}`, { [key === 'address' ? 'address_attributes' : key]: value });
    modifyItems({ ...infosModal, [key]: value });
    setInfosModal({ ...infosModal, [key]: value });
    setModifyFields((prev) => ({ ...prev, [key]: false }))
  }

  const infoModalPrestationType = React.useMemo(() => {
    let prestaType = '';
    if (infosModal.slot_id) {
      const prestation = prestationsList.find(prestation =>
        prestation.slots.some(slot => slot.id === infosModal.slot_id)
      );
      if (prestation && prestation?.presta_type) prestaType = prestation.presta_type;
    }
    return prestaType
  }, [infosModal, prestationsList])

  return (
    <ModalContainer setInfosModal={setInfosModal} infoModalStyle={{ minWidth: 500 }} >
      <div style={{ display: "flex", flexDirection: "column", gap: "12px" }} onClick={() => setModifyFields({})}>
        <div className="section-title">Informations du spot</div>
        {infoModalPrestationType === 'Flying Hand' && <div className="spot-key-value-conatiner">
          <div className="spot-key">Nom :</div>
          {!modifyFields.title
            ? (
              <div
                className="spot-value"
                onDoubleClick={() => setModifyFields((prev) => ({ ...prev, title: true }))}
              >
                {infosModal?.title || "[Nouvelle nom]"}
              </div>
            )
            : (
              <div>
                <InputChangeName
                  text={infosModal?.title}
                  handleChange={(val) => handleSendNewField("title", val)}
                />
              </div>
            )}
        </div>}
        {infoModalPrestationType === 'Flying Hand'
          ? <div className="spot-key-value-conatiner">
            <div className="spot-key">Adresse :</div>
            {!modifyFields.address
              ? (
                <div
                  className="spot-value"
                  onDoubleClick={() => setModifyFields((prev) => ({ ...prev, address: true }))}
                >
                  {infosModal?.address?.label}
                </div>
              )
              : (
                <div style={{ width: '100%' }} onClick={(e) => e.stopPropagation()}>
                  <SelectAddressComponent className="spot-infomodal-input-address" address={infosModal?.address?.label ? infosModal.address.label : null} onSelectAddress={(e) => handleSendNewField("address", e)} label='Adresse:' />
                </div>
              )}
          </div>
          : <div className="spot-key-value-conatiner">
            <div className="spot-key">Adresse :</div>
            <div
              className="spot-value"
              onDoubleClick={() => {
                if (infoModalPrestationType === 'Flying Hand') { setModifyFields((prev) => ({ ...prev, address: true })) }
              }}
            >
              {infosModal?.address?.label}
            </div>
          </div>}
        <div className="spot-key-value-conatiner">
          <div className="spot-key">Description : </div>
          {!modifyFields.description
            ? (
              <div
                className="spot-value"
                onDoubleClick={() => setModifyFields((prev) => ({ ...prev, description: true }))}
              >
                {infosModal?.description || "[Nouvelle description]"}
              </div>
            )
            : (
              <div>
                <InputChangeName
                  text={infosModal?.description}
                  handleChange={(val) => handleSendNewField("description", val)}
                />
              </div>
            )}
        </div>
        {infoModalPrestationType === 'Flying Hand' && <div className="spot-key-value-conatiner">
          <div className="spot-key">Type :</div>
          {!modifyFields.spot_type
            ? (
              <div
                className="spot-value"
                onDoubleClick={() => setModifyFields((prev) => ({ ...prev, spot_type: true }))}
              >
                {infosModal?.spot_type || "[Nouvelle type]"}
              </div>
            )
            : (
              <div>
                <SelectChangeName
                  value={infosModal?.spot_type}
                  options={spotsTypesList}
                  handleChange={(val) => handleSendNewField("spot_type", val)}
                />
              </div>
            )}
        </div>}
        {infoModalPrestationType === 'Flying Hand' && <div className="spot-key-value-conatiner">
          <div className="spot-key">Nombre moyen de flyers distribués :</div>
          <div className="spot-value" style={{ cursor: 'auto' }}>
            {infosModal?.average_distributed_flyers ?? '-'}
          </div>
        </div>}
        {infoModalPrestationType === 'Flying Hand' && <div className="spot-key-value-conatiner">
          <div className="spot-key">Nombre total de flyers distribués :</div>
          <div className="spot-value" style={{ cursor: 'auto' }}>
            {infosModal?.total_distributed_flyers ?? '-'}
          </div>
        </div>}
        {infoModalPrestationType === 'Flying Hand' && <div className="spot-key-value-conatiner">
          <div className="spot-key">Note moyenne :</div>
          <div className="spot-value" style={{ cursor: 'auto' }}>
          {infosModal?.average_note != null
            ? (
              Array.from({ length: 5 }).map((_, index) => (
                <Star2Svg
                  key={index}
                  color={index < parseInt(infosModal?.average_note, 10) ? "#60EED2" : "white"}
                  height={20}
                  width={20}
                />
              ))
            )
            : (<span>[PAS DE NOTE]</span>)
          }
          </div>
        </div>}
        {infoModalPrestationType === 'Flying Hand' &&
        <>
          <div className="section-title">Informations cadence de distrib</div>
          <div className="spot-key-value-conatiner">
            <div className="spot-key">Nom du SM : </div>
            <div className="spot-value" style={{ cursor: 'auto' }}>
                  {infosModal?.sms_names?.length ? infosModal?.sms_names?.join(" / ") : "-"}
            </div>
          </div>
          <div className="spot-key-value-conatiner">
            <div className="spot-key">Total heures sans pause : </div>
            <div className="spot-value" style={{ cursor: 'auto' }}>
                  {infosModal?.total_hours_without_pause ? formatHoursToHHMM(infosModal?.total_hours_without_pause) : '-'}
            </div>
          </div>
          <div className="spot-key-value-conatiner">
            <div className="spot-key">Nombre total de flyers distribués : </div>
            <div className="spot-value" style={{ cursor: 'auto' }}>
                  {infosModal?.total_distributed_flyers ?? "-"}
            </div>
          </div>
          <div className="spot-key-value-conatiner" style={{ fontWeight: 700 }}>
            <div className="spot-key">Nombre de flyers par heure : </div>
            <div className="spot-value" style={{ cursor: 'auto' }}>
                  {Math.round((infosModal?.total_hours_without_pause > 0 ? infosModal?.total_distributed_flyers / infosModal?.total_hours_without_pause : 0))}
            </div>
          </div>
        </>}
        <div
          style={{
            display: "flex",
            alignItems: "center",
            margin: 5,
            cursor: "pointer",
            gap: 5,
            userSelect: 'none',
            width: 120
          }}
          onClick={() => setPickColor((prev) => !prev)}
        >
          <FormatColorFillRoundedIcon />
          Couleur et Rayon
        </div>
      </div>
      {pickColor && (
        <div style={{ display: "flex", flexDirection: "column", padding: 10 }}>
          <div>
            <CompactPicker
              onChangeComplete={(e) => {
                handleModifLayer({ fillColor: e?.hex });
                sendModifyToBackend({ fillColor: e?.hex });
              }}
            />
          </div>
          <>
            <div style={{ display: "flex", alignItems: "center" }}>
              <div>{infoModalPrestationType === 'Flying Hand' ? 'Activer le Geofence :' : 'Activer le rayon :'}</div>
              <Checkbox
                checked={!rayonDisabled}
                onChange={handleChangeRayonVisible}
              />
            </div>
            {!rayonDisabled && (
              <>
                <p>Rayon de la zone</p>
                <input
                  type={"range"}
                  max={1000}
                  min={50}
                  defaultValue={infosModal.geofence_radius}
                  onChange={(e) =>
                    handleModifLayer({
                      geofence_radius: parseInt(e?.target?.value),
                      radius: parseInt(e?.target?.value),
                    })
                  }
                  onMouseUp={(e) =>
                    sendModifyToBackend({
                      geofence_radius: parseInt(e?.target?.value),
                      radius: parseInt(e?.target?.value),
                    })
                  }
                />
              </>
            )}
          </>
        </div>
      )}
    </ModalContainer>
  );
};

const AnomalieInfos = () => {
  const [anomaliesTypesList, setAnomaliesTypesList] = useState([]);
  const { infosModal, setInfosModal, modifyItems, removeItems, updateSingleItemVisibilityByType } =
    useCampaignMap();
  const [modifyFields, setModifyFields] = useState({});

  const smFullName = React.useMemo(() => {
    if (infosModal?.mission?.user?.first_name || infosModal?.mission?.user?.last_name) {
      return `${infosModal?.mission?.user?.first_name ?? ''} ${infosModal?.mission?.user?.last_name ?? ''}`
    }
    return '-'
  }, [infosModal])

  useEffect(() => {
    API.get('/spots/get_anomalies_types')
      .then((res) => {
        if (res?.data?.anomalies_types) {
          setAnomaliesTypesList(res.data.anomalies_types)
        }
      })
  }, [])

  function handleSendNewField(key, value) {
    API.patch(`spots/${infosModal.id}`, { [key === 'address' ? 'address_attributes' : key]: value });
    modifyItems({ ...infosModal, [key]: value });
    setInfosModal({ ...infosModal, [key]: value });
    setModifyFields((prev) => ({ ...prev, [key]: false }))
  }

  const handleDeleteAnomalieAction = () => {
    API.patch(`spots/${infosModal.id}`, { admin_visible: false, client_visible: false });
    updateSingleItemVisibilityByType(infosModal.id, 'spots', false);
    removeItems(infosModal)
    setInfosModal(null)
  }

  return (
    <ModalContainer setInfosModal={setInfosModal} infoModalStyle={{ minWidth: 500 }} >
      <div className="anomalie-info-delete-btn-container" onClick={handleDeleteAnomalieAction}>
        <DeleteRoundedIcon color="error" />
      </div>
      <div style={{ display: "flex", flexDirection: "column", gap: "12px" }} onClick={() => setModifyFields({})}>
        <div className="spot-key-value-conatiner">
          <div className="spot-key">Adresse :</div>
          {!modifyFields.address
            ? (
              <div
                className="spot-value"
                onDoubleClick={() => setModifyFields((prev) => ({ ...prev, address: true }))}
              >
                {infosModal?.address?.label}
              </div>
            )
            : (
              <div style={{ width: '90%' }} onClick={(e) => e.stopPropagation()}>
                <SelectAddressComponent className="spot-infomodal-input-address" address={infosModal?.address?.label ? infosModal.address.label : null} onSelectAddress={(e) => handleSendNewField("address", e)} label='Adresse:' />
              </div>
            )}
        </div>
        <div className="spot-key-value-conatiner">
          <div className="spot-key">Type d'anomalie :</div>
          {!modifyFields.anomalie_type
            ? (
              <div
                className="spot-value"
                onDoubleClick={() => setModifyFields((prev) => ({ ...prev, anomalie_type: true }))}
              >
                {infosModal?.anomalie_type || "[Nouvelle type]"}
              </div>
            )
            : (
              <div>
                <SelectChangeName
                  value={infosModal?.anomalie_type}
                  options={anomaliesTypesList}
                  handleChange={(val) => handleSendNewField("anomalie_type", val)}
                  canAddCustom={false}
                />
              </div>
            )}
        </div>
        {infosModal?.anomalie_type === 'Autres...' && <div className="spot-key-value-conatiner">
          <div className="spot-key">Détail : </div>
          {!modifyFields.anomalie_description
            ? (
              <div
                className="spot-value"
                onDoubleClick={() => setModifyFields((prev) => ({ ...prev, anomalie_description: true }))}
              >
                {infosModal?.anomalie_description || "[Nouvelle détail]"}
              </div>
            )
            : (
              <div>
                <InputChangeName
                  text={infosModal?.anomalie_description}
                  handleChange={(val) => handleSendNewField("anomalie_description", val)}
                />
              </div>
            )}
        </div>}
        <div className="spot-key-value-conatiner">
          <div className="spot-key">SM :</div>
          <div className="spot-value" style={{ cursor: 'auto' }}>
            {smFullName}
          </div>
        </div>
      </div>


    </ModalContainer>
  );
};

export const AnomaliesInfos = () => {
  const [anomaliesTypesList, setAnomaliesTypesList] = useState([]);
  const {
    setInfosModal,
    modifyItems,
    removeItems,
    updateSingleItemVisibilityByType,
    setAnomaliesInfosModal,
    anomaliesInfosModal,
  } = useCampaignMap();
  const [modifyFields, setModifyFields] = useState({});

  const smFullNames = React.useMemo(() => {
    return anomaliesInfosModal.map((modal) => {
      if (modal?.mission?.user?.first_name || modal?.mission?.user?.last_name) {
        return `${modal?.mission?.user?.first_name ?? ''} ${modal?.mission?.user?.last_name ?? ''}`;
      }
      return '-';
    });
  }, [anomaliesInfosModal]);

  useEffect(() => {
    API.get('/spots/get_anomalies_types').then((res) => {
      if (res?.data?.anomalies_types) {
        setAnomaliesTypesList(res.data.anomalies_types);
      }
    });
  }, []);

  function handleSendNewField(infosModal, key, value, index) {
    API.patch(`spots/${infosModal.id}`, {
      [key === 'address' ? 'address_attributes' : key]: value,
    });
    modifyItems({ ...infosModal, [key]: value });
    setAnomaliesInfosModal((prev) => {
      return prev.map(anomalie => anomalie.id === infosModal.id ? { ...anomalie, [key]: value } : anomalie)
    })
    setModifyFields((prev) => ({ ...prev, [index]: { ...(prev?.[index] ?? {}), [key]: false } }));
    if (key === 'address') {
      setAnomaliesInfosModal((prev) => prev.filter((anomalie) => anomalie.id !== infosModal.id))
    }
  }

  const handleDeleteAnomalieAction = (infosModal) => {
    API.patch(`spots/${infosModal.id}`, { admin_visible: false, client_visible: false });
    updateSingleItemVisibilityByType(infosModal.id, 'spots', false);
    removeItems(infosModal);
    setAnomaliesInfosModal((prev) => prev.filter((anomalie) => anomalie.id !== infosModal.id))
  };

  return (<ModalContainer setInfosModal={setInfosModal} infoModalStyle={{ minWidth: 500 }}>
    <div style={{ overflow: 'auto', maxHeight: 270 }}>
      {anomaliesInfosModal.map((infosModal, index) => (<div style={{ position: 'relative' }}>
      <div
        className="anomalie-info-delete-btn-container"
        onClick={() => handleDeleteAnomalieAction(infosModal)}
      >
        <DeleteRoundedIcon color="error" />
      </div>
      <div
        style={{ display: 'flex', flexDirection: 'column', gap: '12px' }}
        onClick={() => setModifyFields({})}
      >
        <div className="spot-key-value-conatiner">
          <div className="spot-key">Adresse :</div>
          {!modifyFields?.[index]?.address ? (
            <div
              className="spot-value"
              onDoubleClick={() =>
                setModifyFields((prev) => ({ ...prev, [index]: { ...(prev?.[index] ?? {}), address: true } }))
              }
            >
              {infosModal?.address?.label}
            </div>
          ) : (
            <div
              style={{ width: '90%' }}
              onClick={(e) => e.stopPropagation()}
            >
              <SelectAddressComponent
                className="spot-infomodal-input-address"
                address={infosModal?.address?.label || null}
                onSelectAddress={(e) =>
                  handleSendNewField(infosModal, 'address', e, index)
                }
                label="Adresse:"
              />
            </div>
          )}
        </div>
        <div className="spot-key-value-conatiner">
          <div className="spot-key">Type d'anomalie :</div>
          {!modifyFields?.[index]?.anomalie_type ? (
            <div
              className="spot-value"
              onDoubleClick={() =>
                setModifyFields((prev) => ({ ...prev, [index]: { ...(prev?.[index] ?? {}), anomalie_type: true } }))
              }
            >
              {infosModal?.anomalie_type || '[Nouvelle type]'}
            </div>
          ) : (
            <div>
              <SelectChangeName
                value={infosModal?.anomalie_type}
                options={anomaliesTypesList}
                handleChange={(val) =>
                  handleSendNewField(infosModal, 'anomalie_type', val, index)
                }
                canAddCustom={false}
              />
            </div>
          )}
        </div>
        {infosModal?.anomalie_type === 'Autres...' && (
          <div className="spot-key-value-conatiner">
            <div className="spot-key">Détail : </div>
            {!modifyFields?.[index]?.anomalie_description ? (
              <div
                className="spot-value"
                onDoubleClick={() =>
                  setModifyFields((prev) => ({ ...prev, [index]: { ...(prev?.[index] ?? {}), anomalie_description: true } }))
                }
              >
                {infosModal?.anomalie_description || '[Nouvelle détail]'}
              </div>
            ) : (
              <div>
                <InputChangeName
                  text={infosModal?.anomalie_description}
                  handleChange={(val) =>
                    handleSendNewField(infosModal, 'anomalie_description', val, index)
                  }
                />
              </div>
            )}
          </div>
        )}
        <div className="spot-key-value-conatiner">
          <div className="spot-key">SM :</div>
          <div className="spot-value" style={{ cursor: 'auto' }}>
            {smFullNames[index]}
          </div>
        </div>
      </div>
      {index < (anomaliesInfosModal.length - 1)&& <Divider style={{ marginTop: '20px', marginBottom: '20px' }}/>}
      </div>))}
    </div>
    </ModalContainer>)
};

function handleModalType(type, isAnomalie) {
  switch (type) {
    case "zone":
      return <PolygonInfos />;
    case "spot":
      if (!isAnomalie)
        return <MarkerInfos />;
      else return <AnomalieInfos />
  }
}

const InfosModal = () => {
  const { infosModal } = useCampaignMap();

  return (
    <OverlayView position={infosModal} mapPaneName={"floatPane"}>
      {handleModalType(getItemType(infosModal), infosModal.anomalie_type)}
    </OverlayView>
  );
};

export default InfosModal;
