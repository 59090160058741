import { createSlice } from "@reduxjs/toolkit";

const fetchingData = { fetching: true };

export const campaignListAction = createSlice({
  name: "campaignList",
  initialState: {
    type: "view",
    fetching: false,
    adminsList: [],
    companyList: [],
    list: [], // {campaign:{...}}[]
    assignmentList: [],
    changeAssignments: {},
    newAssignments: [],
    newSlots: [],
    currentPrestations: [],
    currentCampaign: {},
  },
  reducers: {
    getCampaignsList: (state) => ({ ...state, ...fetchingData }),
    campaignsListFetched: (state, action) => ({
      ...state,
      list: action.payload,
      fetching: false,
    }),
    updateCampaignList: (state, action) => ({
      ...state,
      list: state.list.filter((item) =>
        action.payload.includes(item.campaign.id)
      ),
    }),
    clearCampaignsList: (state) => {
      return { ...state, list: [] };
    },
    setCampaignsList: (state, action) => {
      let updatedList = [];
      if (state.list?.length > 0) {
        updatedList = [...state.list];
      }
      action.payload.forEach((payloadItem) => {
        const existingIndex = state.list.findIndex(
          (item) => item.campaign.id === payloadItem.campaign.id
        );
        if (existingIndex !== -1) {
          updatedList[existingIndex] = {
            campaign: {
              ...state.list[existingIndex].campaign,
              ...payloadItem.campaign,
            },
          };
        } else {
          updatedList.push(payloadItem);
        }
      });
      return { ...state, list: updatedList };
    },
    addSlot: (state, action) => {
      const { campaignId, prestationId, addSlot, address } = action.payload;
      return {
        ...state,
        list: state.list.map((item) => {
          if (item.campaign.id === campaignId) {
            return {
              campaign: {
                ...item.campaign,
                prestations: item.campaign.prestations.map((prestation) => {
                  if (prestation.id === prestationId) {
                    return {
                      ...prestation,
                      slots: [
                        ...prestation.slots,
                        {
                          ...addSlot,
                          address,
                        },
                      ],
                    };
                  }
                  return prestation;
                }),
              },
            };
          }
          return item;
        }),
      };
    },
    updateSlot: (state, action) => {
      const { campaignId, prestationId, slotId, updatedSlot } = action.payload;
      return {
        ...state,
        list: state.list.map((item) => {
          if (item.campaign.id === campaignId) {
            return {
              campaign: {
                ...item.campaign,
                prestations: item.campaign.prestations.map((prestation) => {
                  if (prestation.id === prestationId) {
                    return {
                      ...prestation,
                      slots: prestation.slots.map((slot) => {
                        return slot.id === slotId ? updatedSlot : slot;
                      }),
                    };
                  }
                  return prestation;
                }),
              },
            };
          }
          return item;
        }),
      };
    },
    deleteTeam: (state, action) => {
      const { campaignId, prestationId, teamId } = action.payload;
      return {
        ...state,
        list: state.list.map((item) => {
          if (item.campaign.id === campaignId) {
            return {
              campaign: {
                ...item.campaign,
                teams: item.campaign.teams.filter((t) => t.id !== teamId),
                prestations: item.campaign.prestations.map((prestation) => {
                  if (prestation.id === prestationId) {
                    return {
                      ...prestation,
                      slots: prestation.slots.map((slot) => ({
                        ...slot,
                        teams: slot?.teams?.filter(
                          (team) => team.id !== teamId
                        ),
                      })),
                    };
                  }
                  return prestation;
                }),
              },
            };
          }
          return item;
        }),
      };
    },
    removeSlot: (state, action) => {
      const { campaignId, prestationId, slotId } = action.payload;
      return {
        ...state,
        list: state.list.map((item) => {
          if (item.campaign.id === campaignId) {
            return {
              campaign: {
                ...item.campaign,
                prestations: item.campaign.prestations.map((prestation) => {
                  if (prestation.id === prestationId) {
                    return {
                      ...prestation,
                      slots: prestation.slots.filter(
                        (slot) => slot.id !== slotId
                      ),
                    };
                  }
                  return prestation;
                }),
              },
            };
          }
          return item;
        }),
      };
    },
    addMission: (state, action) => {
      const { campaignId, prestationId, slotId, addMission } = action.payload;
      return {
        ...state,
        list: state.list.map((item) => {
          if (item.campaign.id === campaignId) {
            return {
              campaign: {
                ...item.campaign,
                prestations: item.campaign.prestations.map((prestation) => {
                  if (prestation.id === prestationId) {
                    return {
                      ...prestation,
                      slots: prestation.slots.map((slot) => {
                        if (slot.id === slotId) {
                          return {
                            ...slot,
                            missions:
                              slot?.missions?.length > 0
                                ? [...slot.missions, addMission]
                                : [addMission],
                          };
                        }
                        return slot;
                      }),
                    };
                  }
                  return prestation;
                }),
              },
            };
          }
          return item;
        }),
      };
    },
    afterMissionValidate: (state, action) => {
      // missionId here is the one that got validated, so all others should be already refused
      const { campaignId, prestationId, slotId, missionId } = action.payload;
      return {
        ...state,
        list: state.list.map((item) => {
          if (item.campaign.id === campaignId) {
            return {
              campaign: {
                ...item.campaign,
                prestations: item.campaign.prestations.map((prestation) => {
                  if (prestation.id === prestationId) {
                    return {
                      ...prestation,
                      slots: prestation.slots.map((slot) => {
                        if (slot.id === slotId) {
                          return {
                            ...slot,
                            missions: slot.missions.map((mission) => {
                              return mission.id === missionId
                                ? mission
                                : { ...mission, status: "Refusé" };
                            }),
                          };
                        }
                        return slot;
                      }),
                    };
                  }
                  return prestation;
                }),
              },
            };
          }
          return item;
        }),
      };
    },
    updateMission: (state, action) => {
      const { campaignId, prestationId, slotId, missionId, updatedMission } =
        action.payload;
      return {
        ...state,
        list: state.list.map((item) => {
          if (item.campaign.id === campaignId) {
            return {
              campaign: {
                ...item.campaign,
                prestations: item.campaign.prestations.map((prestation) => {
                  if (prestation.id === prestationId) {
                    return {
                      ...prestation,
                      slots: prestation.slots.map((slot) => {
                        if (slot.id === slotId) {
                          return {
                            ...slot,
                            missions: slot.missions.map((mission) => {
                              return mission.id === missionId
                                ? { ...mission, ...updatedMission }
                                : mission;
                            }),
                          };
                        }
                        return slot;
                      }),
                    };
                  }
                  return prestation;
                }),
              },
            };
          }
          return item;
        }),
      };
    },
    modifyCampaignInfo: (state, action) => {
      const { id, campaignData } = action.payload;
      return {
        ...state,
        list: state.list.map((campaign) => {
          if (campaign.campaign.id === id) {
            return {
              ...campaign,
              campaign: {
                ...campaign.campaign,
                ...campaignData,
              },
            };
          }
          return campaign;
        }),
      };
    },
    modifyDocumentCampaignInfo: (state, action) => {
      const { id, documentsData } = action.payload;
      return {
        ...state,
        list: state.list.map((item) => {
          if (item.campaign.id === id) {
            return {
              campaign: {
                ...item.campaign,
                documents: [...item.campaign.documents, documentsData],
              },
            };
          }
          return item;
        }),
      };
    },
    deleteDocumentCampaignInfo: (state, action) => {
      return {
        ...state,
        list: state.list.map((campaign) => {
          if (campaign.campaign.id === action.payload.campaign_id) {
            return {
              ...campaign,
              documents: campaign.documents.filter(
                (doc) => doc.id !== action.payload.document_id
              ),
            };
          }
          return campaign;
        }),
      };
    },
    modifyCompanyInfo: (state, action) => {
      const { id, company } = action.payload;
      return {
        ...state,
        list: state.list.map((campaign) => {
          if (campaign.campaign.id === id) {
            return {
              ...campaign,
              company: {
                ...campaign.company,
                ...company,
              },
            };
          }
          return campaign;
        }),
      };
    },
    modifyPrestationInfo: (state, action) => {
      const { campaignId, prestationId, prestationInfo } = action.payload;
      return {
        ...state,
        list: state.list.map((item) => {
          if (item.campaign.id === campaignId) {
            return {
              campaign: {
                ...item.campaign,
                prestations: item.campaign.prestations.map((prestation) => {
                  if (prestation.id === prestationId) {
                    return {
                      ...prestation,
                      ...prestationInfo,
                    };
                  }
                  return prestation;
                }),
              },
            };
          }
          return item;
        }),
      };
    },
    deleteCampaignAction: (state, action) => {
      const { id } = action.payload;
      return {
        ...state,
        list: state.list.filter((campaign) => campaign.campaign.id !== id),
      };
    },
    addPrestation: (state, action) => {
      const { campaignId, prestationData } = action.payload;
      return {
        ...state,
        list: state.list.map((item) => {
          if (item.campaign.id === campaignId) {
            return {
              campaign: {
                ...item.campaign,
                prestations: [...item.campaign.prestations, prestationData],
              },
            };
          }
          return item;
        }),
      };
    },
    deletePrestation: (state, action) => {
      const { campaignId, prestationId } = action.payload;
      return {
        ...state,
        list: state.list.map((item) => {
          if (item.campaign.id === campaignId) {
            return {
              campaign: {
                ...item.campaign,
                prestations: item.campaign.prestations.filter(
                  (prestation) => prestation.id !== prestationId
                ),
              }
            };
          }
          return item;
        }),
      };
    },
  },
});

export const {
  getCampaignsList,
  campaignsListFetched,
  updateCampaignList,
  setCampaignsList,
  clearCampaignsList,
  addSlot,
  updateSlot,
  removeSlot,
  deleteTeam,
  addMission,
  updateMission,
  afterMissionValidate,
  modifyCampaignInfo,
  deleteDocumentCampaignInfo,
  modifyCompanyInfo,
  modifyPrestationInfo,
  deleteCampaignAction,
  modifyDocumentCampaignInfo,
  addPrestation,
  deletePrestation,
} = campaignListAction.actions;

export default campaignListAction.reducer;
