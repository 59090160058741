/* eslint-disable */
import React, { createContext, useContext, useEffect, useRef, useState } from 'react'
import axios from 'axios'
import API from 'services/axios-config'
import { useSearchParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { getItemType } from './MapLayout/LeftLayout/DraggableList/function'
import { removeLastItemStack, resetItemsDrag, resetState, selectInitial } from '_redux/Map/reducers'
import { fromKMLtoGeoJSON, fromGeoJSONToKML, fromZoneToGeoJSON, toWKT, toWKTbis, wktToPath } from 'views/Admin/Views/Cartes/functions/functionsMap'
import { parse } from 'wellknown'
import { saveAs } from 'file-saver'
import { adressFormat } from 'components/SelectAddressComponent/functions'
import { enqueueSnackbar } from 'notistack'

const CampaignMapContext = createContext()
const autocompleteService = { current: null }

export const CampaignMapProvider = ({ children }) => {
  const mapRef = useRef()
  const searchLocation = useRef()
  const searchBoxRef = useRef()
  const [prestationsList, setPrestationsList] = useState([])
  const [clientVisibility, setClientVisibility] = useState(null)
  const [mapItemsVisibility, setMapItemsVisibility] = useState({})
  const [currentLocation, setCurrentLocation] = useState(null)
  const [searchParams] = useSearchParams()
  const [multiSelectType, setMultiSelectType] = useState('default')
  const [currentPolygonSelect, setCurrentPolygonSelect] = useState(null)
  const [infosModal, setInfosModal] = useState(null)
  const [anomaliesInfosModal, setAnomaliesInfosModal] = useState(null)
  const [waitingSend, setWaitingSend] = useState(false)
  const [waitingSendDoneCount, setWaitingSendDoneCount] = useState(null)
  const [waitingSendTotalCount, setWaitingSendTotalCount] = useState(null)
  const stackTrace = useSelector(state => state.map.stackTrace)
  const itemsDragSelected = useSelector(state => state.map.itemsDragSelected)
  const slotSelected = useSelector(state => state.map.slotSelected)
  const initialSelected = useSelector(state => state.map.initialSelected)
  const [currentMissionsLastLocations, setCurrentMissionsLastLocations] = useState({})
  const [anomaliesParamsListForMarkerCluster, setAnomaliesParamsListForMarkerCluster] = useState([])

  const localStorageKeyForCampaignVisiblity = React.useMemo(() => {
    const campaignId = searchParams?.get('campaign_id')
    if (campaignId) {
      const key = `campaign_${campaignId}_map_visiblity`
      return key;
    } return null
  }, [])

  useEffect(() => {
    if (localStorageKeyForCampaignVisiblity && Object.keys(mapItemsVisibility).length !== 0) {
      localStorage.setItem(localStorageKeyForCampaignVisiblity, JSON.stringify(mapItemsVisibility))
    }
  }, [mapItemsVisibility, localStorageKeyForCampaignVisiblity])

  useEffect(() => {
    if (localStorageKeyForCampaignVisiblity) {
      const parsedLocalStorageVisiblityData = JSON.parse(localStorage.getItem(localStorageKeyForCampaignVisiblity) ?? "{}")
      if (parsedLocalStorageVisiblityData) setMapItemsVisibility(parsedLocalStorageVisiblityData)
    }
  }, [localStorageKeyForCampaignVisiblity])

  const updateSingleItemVisibilityByType = (id, type, value) => {
    setMapItemsVisibility(prev => {
      return {
        ...(prev ?? {}),
        [type]: {
          ...(prev?.[type] ?? {}),
          [id]: value
        },
      }
    })
  }

  const updateItemsVisibility = (items, value) => {
    const { zonesIds, spotsIds, missionsIds } = items.reduce((acc, item) => {
      const itemType = getItemType(item);
      switch (itemType) {
        case 'zone':
          acc.zonesIds[item.id] = value;
          break;
        case 'spot':
          acc.spotsIds[item.id] = value;
          break;
        case 'mission':
          acc.missionsIds[item.id] = value;
          break;
        default:
          break;
      }
      return acc;
    }, { zonesIds: {}, spotsIds: {}, missionsIds: {} });

    setMapItemsVisibility(prev => {
      return {
        zones: {
          ...(prev.zones ?? {}),
          ...zonesIds,
        },
        spots: {
          ...(prev.spots ?? {}),
          ...spotsIds,
        },
        missions: {
          ...(prev.missions ?? {}),
          ...missionsIds,
        },
      }
    })
  }
  const dispatch = useDispatch()

  function modifyItems(currentItem) {
    setPrestationsList(prev => prev.map(presta => {
      if (currentItem.anomalie_type && presta.id === currentItem?.mission?.slot?.prestation_id) {
        return ({
          ...presta,
          anomalies: presta.anomalies.map(_anomalie => {
            if (_anomalie.id === currentItem.id && getItemType(_anomalie) === getItemType(currentItem)) {
              return (currentItem)
            } else {
              return (_anomalie)
            }
          })
        })
      } else if (presta.id === currentItem.prestation_id) {
        if (currentItem.slot_id) {
          return ({
            ...presta,
            slots: presta.slots.map(_slot => {
              if (_slot.id === currentItem.slot_id) {
                return ({
                  ..._slot,
                  items: _slot.items.map(_item => {
                    if (_item.id === currentItem.id && getItemType(_item) === getItemType(currentItem)) { return (currentItem) } else { return (_item) }
                  })
                })
              } else { return (_slot) }
            })
          })
        } else {
          return ({
            ...presta,
            initials: presta.initials.map(_initial => {
              if (_initial.id === currentItem.id && getItemType(_initial) === getItemType(currentItem)) {
                return (currentItem)
              } else {
                return (_initial)
              }
            })
          })
        }
      } else {
        return presta
      }
    }))
  }

  function handleZoomMap(item) {
    if (!mapRef.current) return;
    if (getItemType(item) === "zone") {
      const bounds = new window.google.maps.LatLngBounds();
      wktToPath(parse(item.polygon)?.coordinates[0]).forEach(coord => {
        bounds.extend(coord)
      });
      mapRef.current.fitBounds(bounds);
    } else if (getItemType(item) === "spot") {
      mapRef.current.panTo({ lat: item.address.latitude, lng: item.address.longitude });
      mapRef.current.setZoom(15);
    } else if (getItemType(item) === "mission") {
      if (item.sorted_positions?.length > 0) {
        const bounds = new window.google.maps.LatLngBounds();
        item.sorted_positions.forEach(pos => {
          pos?.forEach(_res => bounds.extend({ lng: _res?.longitude, lat: _res?.latitude }))
        })
        mapRef.current.fitBounds(bounds);
      }
    }
  }

  useEffect(() => {
    if (!clientVisibility?.items?.length > 0) {
      setClientVisibility(null)
    }
  }, [clientVisibility])

  useEffect(() => {
    dispatch(resetState())
  }, [])

  function createItems(currentItem) {
    setPrestationsList(prev => prev.map(presta => {
      if (!currentItem.prestation_id || presta.id === currentItem.prestation_id) {
        if (currentItem.slot_id) {
          return ({
            ...presta,
            slots: presta.slots.map(_slot => {
              if (_slot.id === currentItem.slot_id) {
                return ({
                  ..._slot,
                  items: [..._slot.items, currentItem]
                })
              } else { return (_slot) }
            }) ?? []
          })
        } else {
          return ({
            ...presta,
            initials: [...presta.initials, currentItem]
          })
        }
      } else {
        return presta
      }
    }))
  }

  function removeItems(currentItem) {
    setPrestationsList(prev => prev.map(presta => {
      if (currentItem.anomalie_type && presta.id === currentItem?.mission?.slot?.prestation_id) {
        return ({
          ...presta,
          anomalies: presta.anomalies.filter(_anomalie => !(_anomalie.id === currentItem.id && getItemType(_anomalie) === getItemType(currentItem)))
        })
      } else if (presta.id === currentItem.prestation_id) {
        if (currentItem.slot_id) {
          return ({
            ...presta,
            slots: presta.slots.map(_slot => {
              if (_slot.id === currentItem.slot_id) {
                return ({
                  ..._slot,
                  items: _slot.items.filter(_item => !(_item.id === currentItem.id && getItemType(_item) === getItemType(currentItem)))
                })
              } else { return (_slot) }
            })
          })
        } else {
          return ({
            ...presta,
            initials: presta.initials.filter(_initial => !(_initial.id === currentItem.id && getItemType(_initial) === getItemType(currentItem)))
          })
        }
      } else {
        return presta
      }
    }))
  }

  function removeSlot(currentItem) {
    setPrestationsList(prev => prev.map(presta => {
      if (presta.id === currentItem.prestation_id) {
        return ({
          ...presta,
          slots: presta.slots.filter(_slot => !(_slot.id === currentItem.id))
        })
      } else {
        return presta
      }
    }))
  }

  function modifySlot(currentItem) {
    setPrestationsList(prev => prev.map(presta => {
      if (presta.id === currentItem.prestation_id) {
        return ({
          ...presta,
          slots: presta.slots.map(_slot => {
            if (_slot.id === currentItem.id) {
              return ({ ..._slot, ...currentItem })
            } else {
              return (_slot)
            }
          })
        })
      } else {
        return presta
      }
    }))
  }

  function depileStack() {
    const lastStack = stackTrace.slice(-1)[0]
    if (lastStack) {
      if (getItemType(lastStack.item) === "zone") {
        if (lastStack.type === 'modify') {
          API.patch(`zones/${lastStack.item.id}`, lastStack.item).then(_ => modifyItems(lastStack.item)).catch(_ => enqueueSnackbar("La donnée recherchée n'existe plus", { variant: 'error' }))
        } else if (lastStack.type === 'delete') {
          API.post('zones', { _json: [lastStack.item] })
            .then(res => {
              createItems(res.data[0])
            })
        }
      } else if (getItemType(lastStack.item) === "spot") {
        if (lastStack.type === 'delete') {
          API.post('spots', { _json: [lastStack.item] })
            .then(res => {
              createItems(res.data[0])
            })
        }
      }
    }
    dispatch(removeLastItemStack())
  }

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.metaKey && event.key === 'z') {
        depileStack()
      }
    };
    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [stackTrace])

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.metaKey || event.ctrlKey) {
        setMultiSelectType('cmd');
      }
      if (event.key === "Shift") {
        setMultiSelectType('shift');
      }
    };

    const handleKeyUp = (event) => {
      if (!event.metaKey && !event.ctrlKey) {
        setMultiSelectType('default');
      }
      if (event.key === "Shift") {
        setMultiSelectType('default');
      }
    };
    document.addEventListener("keydown", handleKeyDown);
    document.addEventListener("keyup", handleKeyUp);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
      document.removeEventListener("keyup", handleKeyUp);
    };
  }, [])

  useEffect(() => {
    API.get(`campaigns/${searchParams?.get('campaign_id')}/prestations`)
      .then(res => setPrestationsList(res?.data))
  }, [])

  function handleOpenPolygonInfos(e) {
    if (e.latLng) {
      setInfosModal({ ...currentPolygonSelect, lat: e.latLng.lat(), lng: e.latLng.lng() })
    }
  }

  function handleOpenMarkerInfos(data, spot) {
    if (data.lat && data.lng) {
      setInfosModal({ ...spot, lat: data.lat, lng: data.lng })
      setAnomaliesInfosModal(null)
    }
  }

  function handleClickLocation(e) {
    const latLng = { lat: e.latLng.lat(), lng: e.latLng.lng() }
    if (itemsDragSelected) dispatch(resetItemsDrag())
    if (currentPolygonSelect || infosModal) {
      setInfosModal(null)
      setCurrentPolygonSelect(null)
      return;
    }
    const geocoder = new window.google.maps.Geocoder()
    geocoder
      .geocode({ location: latLng })
      .then((response) => {
        if (response.results[0]) {
          searchLocation.current.value = response.results[0].formatted_address
          setCurrentLocation({ type: "point", value: response.results[0].geometry.location })
        } else {
          window.alert('No results found')
        }
      })
      .catch((err) => window.alert('Geocoder failed due to: ' + err))
  }

  function handleSearchLocation() {
    const places = searchBoxRef.current.getPlaces()
    if (places.length > 0) {
      const bounds = new window.google.maps.LatLngBounds()
      places.forEach((place) => {
        if (place.geometry && place.geometry.viewport) {
          bounds.union(place.geometry.viewport)
        }
      })
      mapRef.current.fitBounds(bounds)
      if (places[0].types.includes('locality') || places[0].types.includes('administrative_area_level_2') || places[0].types.includes('sublocality_level_1')) {
        axios.get(`https://nominatim.openstreetmap.org/search.php?q=${places[0].name}&polygon_geojson=1&format=json`)
          .then(res => setCurrentLocation({ type: "polygon", value: res.data[0].geojson.coordinates }))
      } else {
        setCurrentLocation({ type: "point", value: places[0].geometry.location })
      }
    }
  }

  function handleRemoveSlot(slot) {
    API.delete(`slots/${slot.id}`)
    removeSlot(slot)
    dispatch(resetItemsDrag())
  }

  function handleSelectPolygon(item) {
    setCurrentPolygonSelect(item)
    setCurrentLocation(null)
    setInfosModal(null)
    dispatch(resetItemsDrag())
  }

  function handleSelectPrestation(prestation) {
    dispatch(selectInitial(prestation))
  }

  function onPolygonComplete(polygon) {
    if (!polygon) return;
    if (!Array.isArray(polygon)) {
      console.log("CE N EST PAS UN ARRAY")
      polygon.setMap(null)
    }
    if (slotSelected) {
      console.log("SLOT")
      API.post(`zones`, { _json: [{ polygon: Array.isArray(polygon) ? toWKTbis(polygon) : toWKT(polygon), slot_id: slotSelected.id, prestation_id: slotSelected.prestation_id }] })
        .then(res => createItems(res.data[0]))
    } else if (initialSelected) {
      console.log("INITIAL")
      API.post(`zones`, { _json: [{ polygon: Array.isArray(polygon) ? toWKTbis(polygon) : toWKT(polygon), prestation_id: initialSelected.id }] })
        .then(res => createItems(res.data[0]))
    }
  }

  const onMarkerComplete = (marker) => {
    if (!marker) return;
    marker.setMap(null)
    const adressFormat = (infos, location, formattedAdress) => {
      const findItem = (key) => {
        const value = infos?.find(item => item.types.includes(key))
        return value?.long_name
      }

      const newObject = {
        housenumber: findItem('street_number'),
        street: findItem('route'),
        postcode: findItem('postal_code'),
        city: findItem('locality'),
        longitude: location.lng(),
        latitude: location.lat(),
        label: formattedAdress

      }
      return newObject
    }
    const latLng = marker.position
    const geocoder = new window.google.maps.Geocoder()
    geocoder
      .geocode({ location: { lat: latLng.lat(), lng: latLng.lng() } })
      .then((response) => {
        if (response.results[0]) {
          const new_addr = adressFormat(response.results[0]?.address_components, response.results[0]?.geometry?.location, response.results[0]?.formatted_address)
          if (slotSelected) {
            API.post(`spots`, { _json: [{ address_attributes: new_addr, slot_id: slotSelected.id, prestation_id: slotSelected.prestation_id }] })
              .then(res => createItems(res.data[0]))
          } else if (initialSelected) {
            API.post(`spots`, { _json: [{ address_attributes: new_addr, prestation_id: initialSelected.id }] })
              .then(res => createItems(res.data[0]))
          }
        } else {
          window.alert('No results found')
        }
      })
      .catch((e) => window.alert('Geocoder failed due to: ' + e))
  }

  async function formattedParams(type, params, currentPrestation) {
    const Ids = {
      prestation_id: currentPrestation?.presta_type ? currentPrestation?.id : currentPrestation.prestation_id,
      slot_id: currentPrestation?.presta_type ? undefined : currentPrestation?.id,
    }
    if (type === 'Point') {
      let currentAddress = null
      const latLng = {
        lng: params?.geometry?.coordinates[0],
        lat: params?.geometry?.coordinates[1]
      }
      const geocoder = new window.google.maps.Geocoder()
      await geocoder
        .geocode({ location: latLng })
        .then((response) => {
          if (response.results[0]) {
            const newAddress = adressFormat(
              response.results[0]?.address_components,
              response.results[0]?.geometry?.location,
              response.results[0]?.formatted_address
            )
            currentAddress = newAddress
          } else {
            window.alert('No results found')
          }
        })
        .catch((e) => window.alert('Geocoder failed due to: ' + e))
      return {
        ...Ids,
        address_attributes: currentAddress,
        fillColor: params?.properties['icon-color'],
        type: 'spots'
      }
    }
    if (type === 'Polygon') {
      return {
        ...Ids,
        polygon: toWKTbis(params?.geometry.coordinates[0]),
        fillColor: params?.properties.fill,
        type: 'zones',
        title: params?.properties.name,
      }
    }
  }

  React.useEffect(() => {
    if (!autocompleteService.current && window.google) {
      autocompleteService.current =
        new window.google.maps.places.AutocompleteService()
    }
    if (!autocompleteService.current) {
      return undefined
    }
  }, [fetch])

  const getPlaceDetails = (placeId) => {
    return new Promise((resolve, reject) => {
      new window.google.maps.places.PlacesService(
        document.createElement('div')
      ).getDetails(
        {
          placeId
        },
        (place, status) => {
          if (status === window.google.maps.places.PlacesServiceStatus.OK) {
            resolve(place);
          } else {
            reject(new Error(`L'API Google a retourné une erreur: ${status}`));
          }
        }
      );
    });
  };

  const getAddress = async (request) => {
    if (autocompleteService.current) {
      const { predictions } = await autocompleteService.current.getPlacePredictions({ ...request });
      const prediction = predictions?.[0]
      if (prediction) {
        const place = await getPlaceDetails(prediction.place_id);
        if (place) {
          return adressFormat(place?.address_components, place?.geometry?.location, place?.formatted_address)
        }
      }
      return null;
    } else {
      return null;
    }
  };

  function readCSVFileAsText(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (event) => {
        resolve(event.target.result);
      };
      reader.onerror = (error) => {
        reject(error);
      };
      reader.readAsText(file);
    });
  }

  function detectCSVDelimiter(csvText) {
    const firstLine = csvText.split('\n')[0];
    const delimiterCounts = {
      ',': (firstLine.match(/,/g) || []).length,
      ';': (firstLine.match(/;/g) || []).length,
    };

    const maxDelimiter = Object.keys(delimiterCounts).reduce((a, b) =>
      delimiterCounts[a] > delimiterCounts[b] ? a : b
    );

    return delimiterCounts[maxDelimiter] === 0 ? null : maxDelimiter;
  }

  async function parseCSV(csvText, prestationId, slotId) {
    const delay = ms => new Promise(resolve => setTimeout(resolve, ms));

    const lines = csvText.split('\n')?.filter(line => line.trim() !== '');

    if (lines.length < 2) {
      return { error: "Le fichier CSV doit avoir au minimum 2 lignes (une ligne pour l'en-tête et une ligne pour)." };
    }

    const delimiter = detectCSVDelimiter(lines[0])

    if (!delimiter) {
      return { error: "Le séparateur de fichier CSV doit être ';' ou ','." };
    }

    const headers = lines[0].split(delimiter);
    const adresseCompleteColumnName = headers.find(
      header => header.trim().toLowerCase() === "adresse complète"
    );
    if (!adresseCompleteColumnName) {
      return { error: "Le fichier CSV doit contenir l'en-tête 'adresse complète' (casse insensible)." };
    }

    const adresseCompleteIndex = headers.indexOf(adresseCompleteColumnName);

    const data = [];
    try {
      setWaitingSendTotalCount(lines.length - 1)
      setWaitingSendDoneCount(0)

      for (let i = 1; i < lines.length; i++) {
        const line = lines[i].trim();
        if (line) {
          const values = line.split(delimiter);

          if (values.length !== headers.length) {
            return { error: `La ligne ${i + 1} ne contient pas le bon nombre de colonnes.` };
          }
          if (values[adresseCompleteIndex]) {
            await delay(500);
            const address = await getAddress({ input: values[adresseCompleteIndex], componentRestrictions: { country: 'fr' } });
            if (address.longitude && address.latitude) {
              data.push({
                address_attributes: address,
                title: address?.label,
                description: null,
                spotType: null,
                type: "spots",
                prestation_id: prestationId,
                slot_id: slotId
              });
              setWaitingSendDoneCount(prev => prev + 1)
            } else {
              enqueueSnackbar(`Impossible d'obtenir l'adresse de cette ligne: ${line}`, { variant: 'warning', persist: true })
            }
          } else {
            enqueueSnackbar(`Impossible d'obtenir l'adresse de cette ligne: ${line}`, { variant: 'warning', persist: true })
          }
        }
      }
    } catch (error) {
      enqueueSnackbar(`Erreur lors de la récupération de l'adresse: ${error.message}`, { variant: 'warning', persist: true });
    }

    if (data.length === 0) {
      return { error: "Le fichier CSV est vide ou ne contient pas de lignes de données." };
    }

    return { data };
  }

  async function handleAddKml(currentPrestation) {
    const promises = []
    const newItemsList = []
    let newInitialList = []
    const input = document.createElement('input')
    input.type = 'file'
    input.accept = '.kml, .csv'
    input.onchange = async (e) => {
      setWaitingSend(true)
      const file = e.target.files[0]
      const fileName = file.name
      const fileType = fileName.split('.').pop()
      if (fileType === 'kml') {
        await fromKMLtoGeoJSON(file).then(async (res) => {
          res?.children?.map(async (_child) => {
            if (_child?.children) {
              await _child?.children?.map(async (_calque) => {
                if (_calque?.geometry?.type === 'GeometryCollection') {
                  await _calque?.geometry?.geometries?.map(async (_collect) => {
                    const newPromise = new Promise((resolve) => {
                      formattedParams(_collect?.type, {
                        ..._calque,
                        geometry: _collect
                      }, currentPrestation)
                        .then((res) => {
                          if (res) newItemsList.push(res)
                        })
                        .then((_) => resolve())
                    })
                    promises.push(newPromise)
                  })
                }
                const newPromise = new Promise((resolve) => {
                  formattedParams(_calque?.geometry?.type, _calque, currentPrestation)
                    .then((res) => {
                      if (res) newItemsList.push(res)
                    })
                    .then((_) => resolve())
                })
                promises.push(newPromise)
              })
            } else {
              if (_child?.geometry?.type === 'GeometryCollection') {
                await _child?.geometry?.geometries?.map(async (_collect) => {
                  const newPromise = new Promise((resolve) => {
                    formattedParams(_collect?.type, {
                      ..._child,
                      geometry: _collect
                    }, currentPrestation)
                      .then((res) => {
                        if (res) newItemsList.push(res)
                      })
                      .then((_) => resolve())
                  })
                  promises.push(newPromise)
                })
              }
              if (!_child?.geometry) {
                // KML with no geometry (handeled: Feature that don't have exact coords and only have address string)
                const address = _child?.properties?.address;
                if (!address) {
                  enqueueSnackbar(`Pas trouvé l'adresse dans ces propriétés: ${JSON.stringify(_child?.properties)}`, { variant: 'error', persist: true })
                  return;
                }
                const newPromise = new Promise((resolve) => {
                  getAddress({ input: address, componentRestrictions: { country: 'fr' } }).then((formattedAddress) => {
                    if (!formattedAddress) {
                      enqueueSnackbar(`Google n'a pas trouvé cette adresse: ${address}`, { variant: 'error', persist: true })
                      return;
                    }
                    const data = {
                      address_attributes: formattedAddress,
                      prestation_id: currentPrestation?.presta_type ? currentPrestation?.id : currentPrestation.prestation_id,
                      slot_id: currentPrestation?.presta_type ? undefined : currentPrestation?.id,
                      fillColor: _child?.properties?.['icon-color'],
                      title: _child?.properties?.name,
                      type: "spots"
                    }
                    newItemsList.push(data)
                  }).then((_) => resolve());
                })
                promises.push(newPromise)
              } else {
                const newPromise = new Promise((resolve) => {
                  formattedParams(_child?.geometry?.type, _child, currentPrestation)
                    .then((res) => {
                      if (res) newItemsList.push(res)
                    })
                    .then((_) => resolve())
                })
                promises.push(newPromise)
              }
            }
          })
        })
      } else if (fileType === 'csv') {
        const prestation = currentPrestation.prestation_id ? prestationsList.find(prestation => currentPrestation.prestation_id === prestation.id) : currentPrestation
        const slot = prestation?.id !== currentPrestation.id ? currentPrestation : null
        const csvText = await readCSVFileAsText(file)
        const newPromise = new Promise((resolve) => {
          parseCSV(csvText, prestation.id, slot?.id)
            .then(({ data, error }) => {
              if (error) enqueueSnackbar(error, { variant: 'error', persist: true })
              if (data && data.length > 0) newItemsList.push(...data)
            })
            .then((_) => resolve())
        })
        promises.push(newPromise)
      } else {
        enqueueSnackbar("Cette fonctionnalité n'accepte que les fichiers KML et CSV.", { variant: 'error', persist: false })
      }

      try {
        await Promise.all(promises);
      } catch (err) {
        enqueueSnackbar(err, { variant: 'error', persist: true });
      }
      const zonesList = newItemsList.filter((item) => item.type === 'zones')
      const spotsList = newItemsList.filter((item) => item.type === 'spots')
      const spotsPromise = new Promise((resolve) => {
        API.post('spots', spotsList)
          .then((res) => {
            if (res.data) {
              newInitialList = [
                ...newInitialList,
                ...res.data.map((item) => ({
                  ...item,
                }))
              ]
              return res.data
            }
          })
          .then((data) => resolve(data))
          .catch((err) => enqueueSnackbar(err, { variant: 'error', persist: true }))
      })
      const zonesPromise = new Promise((resolve) => {
        API.post('zones', zonesList)
          .then((res) => {
            if (res.data) {
              newInitialList = [
                ...newInitialList,
                ...res.data.map((item) => ({
                  ...item,
                }))
              ]
              return res.data
            }
          })
          .then((data) => resolve(data))
          .catch((err) => enqueueSnackbar(err, { variant: 'error', persist: true }))
      })
      const [spotsResult, zonesResult] = await Promise.all([spotsPromise, zonesPromise])
      if (spotsResult?.length > 0) enqueueSnackbar(`${spotsResult.length} spots ont été créés avec succès.`, { variant: 'success', autoHideDuration: 5000 })
      if (zonesResult?.length > 0) enqueueSnackbar(`${zonesResult.length} zones ont été créés avec succès.`, { variant: 'success', autoHideDuration: 5000 })

      setWaitingSend(false)
      newInitialList.forEach((item) => { createItems(item) })
    }
    input.click()
  }

  function handleGenerateKml(data) {
    const template = { type: 'FeatureCollection', features: [] }
    if (data.initials?.length > 0) {
      data.initials.forEach((initial) => {
        fromZoneToGeoJSON(initial, template, getItemType(initial) + "s")
      })
    }
    if (data?.slots?.length > 0) {
      data.slots.forEach((slot) => {
        slot.items.forEach((item) => {
          fromZoneToGeoJSON(item, template, getItemType(item) + "s")
        })
      })
    }
    const blob = new Blob([fromGeoJSONToKML(template)], {
      type: 'application/vnd.google-earth.kml+xml'
    })
    saveAs(blob, 'zones.kml')
  }

  function handleClientVisibility(e, item) {
    e.stopPropagation()
    if ((item.client_visible && clientVisibility?.type === "publish") || (!item.client_visible && clientVisibility?.type === "depublish")) {
      enqueueSnackbar(clientVisibility?.type === "publish" ? 'Tu ne peux pas dépublier avant d\'avoir publier tes modifications' : 'Tu ne peux pas publier avant d\'avoir dépublié tes modifications', { variant: 'error' })
      return;
    }
    if (clientVisibility && clientVisibility.items.find(_item => (item?.id === _item.id && getItemType(item) === getItemType(_item)))) {
      setClientVisibility(prev => ({
        ...prev,
        items: prev.items.filter(_item => {
          return !((item?.id === _item.id) && (getItemType(item) === getItemType(_item)))
        })
      }))
    } else {
      if (!clientVisibility) {
        setClientVisibility({ type: item.client_visible ? "depublish" : "publish", items: [item] })
      } else {
        setClientVisibility(prev => ({ ...prev, items: [...prev.items, item] }))
      }
    }
  }
  const [importedAnomalies, setImportedAnomalies] = useState([])
  const [openMissionAnomalieModel, setOpenMissionAnomalieModel] = useState(false)
  const campaignId = searchParams?.get('campaign_id');

  async function parseAnomaliesCSV(csvText) {
    const lines = csvText.split('\n').filter(line => line.trim() !== '');
    if (lines.length < 2) {
      return { error: "Le fichier CSV doit avoir au minimum 2 lignes (une ligne pour l'en-tête et une ligne pour les données)." };
    }

    const delimiter = detectCSVDelimiter(lines[0]);
    if (!delimiter) {
      return { error: "Le séparateur de fichier CSV doit être ';' ou ','." };
    }

    const headers = lines[0].split(delimiter).map(header => header.trim().toLowerCase());
    const addressIndex = headers.indexOf("adresse complète");
    const idIndex = headers.indexOf("anomalie id");
    const typeIndex = headers.indexOf("anomalies");
    const descriptionIndex = headers.indexOf("détails");

    if (addressIndex === -1 || idIndex === -1 || typeIndex === -1 || descriptionIndex === -1) {
      return { error: "Le fichier CSV est manquant des en-têtes requis." };
    }
    const data = [];
    try {
      setWaitingSendTotalCount(lines.length - 1);
      setWaitingSendDoneCount(0);

      const addressRequests = lines.slice(1).map(async (line, i) => {
        const values = line.split(delimiter);
        const addressValue = values[addressIndex]?.trim();

        if (addressValue) {
          try {
            const address = await getAddress({ input: addressValue, componentRestrictions: { country: 'fr' } });
            if (address.longitude && address.latitude) {
                data.push({
                  campaignId: campaignId, 
                  address_attributes: address,
                  id: values[idIndex],
                  anomalieDescription: values[descriptionIndex],
                  anomalieType: values[typeIndex],
                });
              setWaitingSendDoneCount(prev => prev + 1);
            }
          } catch (addressError) {
            console.error(`Erreur pour l'adresse à la ligne ${i + 2}: ${addressError.message}`);
          }
        }
      });

      await Promise.all(addressRequests);

      if (data.length === 0) {
        return { error: "Le fichier CSV est vide ou ne contient pas de lignes de données valides." };
      }
    } catch (error) {
      enqueueSnackbar(error.message, { variant: 'error', persist: true });
    }

    return { data };
  }

  async function importAnomaliesCSV(e, prestaId) {
    try {
      const file = e.target.files[0];
      const csvText = await readCSVFileAsText(file);
      const campaignId = searchParams?.get('campaign_id');

      const { data, error } = await parseAnomaliesCSV(csvText);
      if (error) {
        enqueueSnackbar(error, { variant: 'error', persist: true });
        return;
      }

      const prestation = prestationsList.find(p => p.id === prestaId);
      const newAnomalies = data?.filter(anomaly => 
        !prestation.anomalies.some(existingAnomaly => existingAnomaly.id === parseInt(anomaly.id))
      );
      const updatedAnomalies = data?.filter(anomaly => 
        prestation.anomalies.some(existingAnomaly => existingAnomaly.id === parseInt(anomaly.id))
      );
      setImportedAnomalies({newAnomalies, data: updatedAnomalies, prestaId})

      if (newAnomalies.length > 0) {
        setOpenMissionAnomalieModel(true)
      }
      else {
        const res = await API.post(`/campaigns/${campaignId}/spots/upload_anomalies_csv`, { data, prestaId });
        const spotsResult = res.data.data || [];
        setPrestationsList((prevList) =>
          prevList.map((prestation) => {
              if (prestation.id === prestaId) {
                  return {
                      ...prestation,
                      anomalies: spotsResult
                  };
              }
              return prestation;
          })
        );
        if (spotsResult.length > 0) {
          enqueueSnackbar(`${spotsResult.length} anomalies ont été importés avec succès.`, { variant: 'success', autoHideDuration: 5000 });
        }
      }
    } catch (err) {
      enqueueSnackbar(err.message || "Une erreur est survenue", { variant: 'error', persist: true });
    }
  }

  return (
    <CampaignMapContext.Provider value={{ mapRef, searchLocation, searchBoxRef, currentLocation, handleClickLocation, handleSearchLocation, prestationsList, setPrestationsList, multiSelectType, currentPolygonSelect, handleSelectPolygon, handleOpenPolygonInfos, infosModal, setInfosModal, removeItems, onPolygonComplete, handleSelectPrestation, handleRemoveSlot, onMarkerComplete, handleOpenMarkerInfos, handleZoomMap, modifyItems, modifySlot, clientVisibility, handleAddKml, handleGenerateKml, waitingSend, createItems, handleClientVisibility, setClientVisibility, mapItemsVisibility, setMapItemsVisibility, updateItemsVisibility, updateSingleItemVisibilityByType, waitingSendDoneCount, waitingSendTotalCount, currentMissionsLastLocations, setCurrentMissionsLastLocations, anomaliesParamsListForMarkerCluster, setAnomaliesParamsListForMarkerCluster, anomaliesInfosModal, setAnomaliesInfosModal, importAnomaliesCSV, importedAnomalies, openMissionAnomalieModel, setOpenMissionAnomalieModel }}>
      <div style={stylesContainer}>
        {children}
      </div>
    </CampaignMapContext.Provider>
  )
}

const stylesContainer = {
  width: "100%",
  height: "100%",
  display: "flex"
}

export const useCampaignMap = () => useContext(CampaignMapContext)
