const campaignFilterStatutList = [
  "Brouillon",
  "En recherche de SM",
  "En cours",
  "Terminée",
];
const campaignFilterDateList = [
  "7 DERNIERS JOURS",
  "14 DERNIERS JOURS",
  "28 DERNIERS JOURS",
  "3 DERNIERS MOIS",
];
const campaignFilterPrestationsList = [
  "Flying Box",
  "Flying Hand",
  "Flying Bike",
  "Flying Wall",
  "Flying Store",
];
const campaignFilterResponsableOperationnel = ["THIAGO", "SYFAX"];
const campaignFilterResponsableCommercial = ["CHEIKH"];
export const filters = [
  {
    key: "status",
    label: "STATUT",
    options: campaignFilterStatutList,
    multiple: true,
  },
  {
    key: "date",
    label: "DATE",
    options: campaignFilterDateList,
    multiple: false,
  },
  {
    key: "prestation",
    label: "PRESTATION",
    options: campaignFilterPrestationsList,
    multiple: true,
  },
  {
    key: "responsable_com",
    label: "RESPONSABLE COMMERCIAL",
    options: campaignFilterResponsableCommercial,
    multiple: false,
  },
  {
    key: "responsable_op",
    label: "RESPONSABLE OPÉRATIONNEL",
    options: campaignFilterResponsableOperationnel,
    multiple: false,
  },
];
