/* eslint-disable react/prop-types */
import React from 'react'
import './style.scss'
import CampaignStatusCard from 'components/CampaignStatusCard'
import { useSearchParams } from 'react-router-dom'
import { useSelector } from 'react-redux'

const TopContent = (props) => {
  const { toggleShowDevis, isDevisOpen } = props
  const [searchParams] = useSearchParams()
  const campaignId = searchParams.get('campaign_id')
  const campaignRawList = useSelector(state => state.campaignsList.list.filter(item => item?.campaign?.id === Number(campaignId))[0]?.campaign)

  return (
    <div className='campaignmodif-details_title'>
      <CampaignStatusCard status={campaignRawList.status}/>
      {campaignRawList?.devis && <div className="voir-devis-btn" onClick={toggleShowDevis}>
        {isDevisOpen ? 'FERMER DEVIS' : 'VOIR DEVIS'}
      </div>}
    </div>
  )
}

export default TopContent
