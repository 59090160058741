/* eslint-disable react/prop-types */
/* eslint-disable camelcase */
import React, { useRef, useState } from "react";
import SlotView from "./SlotView";
import { presta_icons } from "./prestaIcon";
import { ReactComponent as ArrowUp } from "assets/arrow-up-right.svg";
import { BlackBtn } from "views/Admin/Shared/Forms/BlackBtn";
import ComponentSlotModification from "views/Slot/SlotCard/ComponentSlotCardView/ComponentSlotModification";
import "./style.scss";
import {
  FlyingBike,
  FlyingBox,
  FlyingHand,
  FlyingStore,
  FlyingWall,
} from "./prestationConfig";
import { useRightModal } from "views/Lists/Campaigns/rightModalContext";
import API from "services/axios-config";
import { Modal, Badge } from "@mui/material";
import { enqueueSnackbar } from "notistack";
import { ReactComponent as PoubelleLogo } from "assets/poubelle.svg";
import loupe from "assets/loupe.png";
import PrestationModification from "views/Slot/SlotCard/ComponentSlotCardView/ComponentPrestationModification";
import { ReactComponent as SettingsLogo } from "assets/pen.svg";
import { useDispatch, useSelector } from "react-redux";
import {
  addSlot,
  modifyPrestationInfo,
  removeSlot,
  modifyDocumentCampaignInfo,
  deleteDocumentCampaignInfo,
} from "_redux/Campaigns/reducers";
import { useSearchParams } from "react-router-dom";

const styles = {
  documentContainer: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: "white",
    border: "2px solid #000",
    boxShadow: "24px",
    width: "100%",
    maxWidth: "600px",
    borderRadius: 20,
  },
  titleDocumentsContainer: {
    display: "flex",
    justifyContent: "space-between",
    padding: "20px 15px",
    borderBottom: "2px solid black",
  },
  centeredContent: {
    display: "flex",
    justifyContent: "center",
    padding: "20px 0",
  },
  docView: {
    height: 50,
    padding: "10px 30px",
    display: "flex",
    borderBottom: "2px solid black",
    justifyContent: "space-between",
    alignItems: "center",
  },
  rightActionDoc: {
    display: "flex",
    gap: 10,
    alignItems: "center",
  },
  documentName: {
    width: "70%",
    fontSize: 14,
    fontWeight: 600,
    color: "black",
  },
  titleModal: {
    fontSize: 14,
    fontWeight: 700,
    color: "black",
  },
};

export const PrestationDocuments = (props) => {
  const { documents, prestation, type, campaign } = props;
  const fileInputRef = useRef(null);
  const [load, setLoad] = useState(false);
  const [documentsList, setDocumentsList] = useState(documents);
  const [disableDelete, setDisableDelete] = useState(false);
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();

  const sendNewFile = (document) => {
    const isTypeCampaign = type === "campaign";
    const headers = { "Content-Type": "multipart/form-data" };
    const formData = new FormData();
    formData.append("document", document.target.files[0]);
    setLoad(true);

    const apiCall = isTypeCampaign
      ? API.post(`campaigns/${campaign?.id}/send_new_document`, formData, {
          headers,
        })
      : API.post(`prestations/${prestation?.id}/send_new_document`, formData, {
          headers,
        });

    apiCall
      .then((res) => {
        setDocumentsList((prev) => [...prev, res.data]);

        if (isTypeCampaign) {
          dispatch(
            modifyDocumentCampaignInfo({
              id: campaign?.id,
              documentsData: res.data,
            })
          );
        } else {
          dispatch(
            modifyPrestationInfo({
              campaignId: parseInt(searchParams?.get("campaign_id")),
              prestationId: prestation?.id,
              prestationInfo: { documents: [...documents, res.data] },
            })
          );
        }
      })
      .catch((err) =>
        enqueueSnackbar(
          "Une erreur est survenue lors de l'envoi du document" + err
        )
      )
      .finally(() => setLoad(false));
  };

  function handleOpen(url) {
    if (url.includes("http")) window.open(url, "_blank");
    else window.open(process.env.REACT_APP_BACK_API + url, "_blank");
  }

  function handleRemoveDoc(document_id) {
    const isTypeCampaign = type === "campaign";
    setDisableDelete(true);

    API.delete(`attachments/${document_id}`)
      .then(() => {
        setDocumentsList((prev) =>
          prev.filter((doc) => doc.id !== document_id)
        );

        if (isTypeCampaign) {
          dispatch(
            deleteDocumentCampaignInfo({
              campaign_id: parseInt(searchParams?.get("campaign_id")),
              document_id,
            })
          );
        } else {
          dispatch(
            modifyPrestationInfo({
              campaignId: parseInt(searchParams?.get("campaign_id")),
              prestationId: prestation?.id,
              prestationInfo: {
                documents: documents.filter((doc) => doc.id !== document_id),
              },
            })
          );
        }
      })
      .catch((err) =>
        enqueueSnackbar(
          "Une erreur est survenue lors de la suppression du document" + err
        )
      )
      .finally(() => setDisableDelete(false));
  }

  return (
    <div style={styles.documentContainer}>
      <div style={styles.titleDocumentsContainer}>
        <div style={styles.titleModal}>Liste des documents :</div>
      </div>
      {documentsList?.map((doc, index) => {
        return (
          <div key={`document-list-${index}`} style={styles.docView}>
            <div style={styles.documentName}>{doc?.file_name}</div>
            <div style={styles.rightActionDoc}>
              <div
                onClick={() => handleOpen(doc.url)}
                style={{ cursor: "pointer" }}
              >
                <img src={loupe} />
              </div>
              <div
                onClick={() => handleRemoveDoc(doc?.id)}
                style={{ cursor: "pointer" }}
                disabled={disableDelete}
              >
                <PoubelleLogo />
              </div>
            </div>
          </div>
        );
      })}
      <div style={styles.centeredContent}>
        <BlackBtn
          label="Ajouter un document"
          styles={{ width: "min-content", textWrap: "nowrap" }}
          handleAction={() => fileInputRef.current.click()}
          disabled={load}
        />
        <input
          type="file"
          ref={fileInputRef}
          style={{ display: "none" }}
          onChange={sendNewFile}
        />
      </div>
    </div>
  );
};

const Slotcontainer = (props) => {
  const { prestation } = props;
  return (
    <div className="slot-container">
      {prestation?.slots.map((slot, index) => {
        return <SlotView {...props} key={`${slot}-${index}`} slot={slot} />;
      })}
    </div>
  );
};

const PrestaContainer = (props) => {
  const { prestation, children, setDocumentsOpen } = props;
  const [searchParams] = useSearchParams()
  const [prestaOpen, setPrestaOpen] = useState(searchParams.get('prestation_id') === prestation.id.toString());

  function handleDocuments(e) {
    e.stopPropagation();
    setDocumentsOpen(true);
  }

  return (
    <div>
      <div
        onClick={() => {
          setPrestaOpen((prev) => !prev);
        }}
        className={`prestation-type ${prestaOpen ? "open" : ""}`}
      >
        <div className="prestation-collabse-bar">
          <div className="prestation-left-bar">
            {prestation?.need_attention && (
              <Badge
                badgeContent="!"
                color="error"
                overlap="circular"
              ></Badge>
            )}
            {presta_icons[prestation?.presta_type]}{" "}
            {prestation?.presta_type?.toUpperCase()}{" "}
            {prestation?.presta_type === "Flying Box" &&
              prestation.bal_type?.toUpperCase()}
          </div>
          <div className="prestation-right-bar">
            <BlackBtn
              label="VOIR LES DOCUMENTS"
              handleAction={handleDocuments}
            />
            <ArrowUp
              className={`presta-icon ${prestaOpen && "open"}`}
              width={20}
              height={20}
            />
          </div>
        </div>
      </div>
      {prestaOpen && children}
    </div>
  );
};

const prestationInfos = (props) => {
  const { addToStack } = useRightModal();
  const { prestation, setDocumentsOpen } = props;

  switch (prestation?.presta_type) {
    case "Flying Box":
      return <FlyingBox {...props} />;
    case "Flying Hand":
      return <FlyingHand {...props} />;
    case "Flying Store":
      return <FlyingStore {...props} />;
    case "Flying Wall":
      return <FlyingWall {...props} />;
    case "Flying Bike":
      return <FlyingBike {...props} />;
    default:
      return (
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div>Choisissez une prestation</div>
          <div
            onClick={() =>
              addToStack({
                component: (
                  <PrestationModification
                    {...{ prestation, setDocumentsOpen }}
                  />
                ),
              })
            }
          >
            <SettingsLogo style={{ width: 30, height: 30 }} fill="black" />
          </div>
        </div>
      );
  }
};

const PrestationBlock = (props) => {
  const [searchParams] = useSearchParams()
  const campaignId = searchParams.get('campaign_id')
  const campaignRawList = useSelector(state => state.campaignsList.list.filter(campaign => campaign?.campaign.id === Number(campaignId))[0]?.campaign)
  const { prestation } = props;
  const { addToStack, removedLastStack } = useRightModal();
  const [documentsOpen, setDocumentsOpen] = useState(false);
  const [, setLoading] = useState(false);
  const dispatch = useDispatch();
  const [, setSearchParams] = useSearchParams()

  function handleRemovedUnusedSlot(slot_id) {
    API.delete(`slots/${slot_id}`).then((_) => {
      dispatch(
        removeSlot({
          campaignId: campaignRawList?.id,
          prestationId: prestation.id,
          slotId: slot_id,
        })
      );
      removedLastStack();
    });
  }

  function handleCreateSlot() {
    setLoading(true);
    API.post(
      `campaigns/${campaignRawList?.id}/prestations/${prestation?.id}/slots`
    )
      .then((res) => {
        setSearchParams(prevParams => {
          const newParams = new URLSearchParams(prevParams);
          newParams.set('prestation_id', res?.data?.prestation_id);
          newParams.set('slot_id', res?.data?.id);
          return newParams;
        });
        addToStack({
          component: (
            <ComponentSlotModification
              slot={res?.data}
              onClose={() => {
                setSearchParams(prevParams => {
                  const newParams = new URLSearchParams(prevParams);
                  newParams.delete('slot_id');
                  newParams.delete('prestation_id');
                  return newParams;
                });
              }}
              onCloseWithoutSaving={() => handleRemovedUnusedSlot(res?.data?.id)}
            />
          ),
          action: () => handleRemovedUnusedSlot(res?.data?.id),
          onClose: () => {
            setSearchParams(prevParams => {
              const newParams = new URLSearchParams(prevParams);
              newParams.delete('slot_id');
              newParams.delete('prestation_id');
              return newParams;
            });
          }
        });
        dispatch(
          addSlot({
            campaignId: campaignRawList?.id,
            prestationId: prestation.id,
            addSlot: res.data,
          })
        );
      })
      .catch((err) => enqueueSnackbar("La création de slot à échoué", err))
      .finally(() => setLoading(false));
  }

  return (
    <div className="prestation-card">
      <Modal open={documentsOpen} onClose={() => setDocumentsOpen(false)}>
        <PrestationDocuments
          documents={prestation?.documents}
          {...{ prestation }}
        />
      </Modal>
      <PrestaContainer {...props} {...{ setDocumentsOpen }}>
        {prestationInfos({ ...props })}
        <div className="slots-list">
          <div className="slots-infos">
            <div className={"slots-open-component open"}>
              {prestation?.slots?.length} CRENEAU
              {prestation?.slots?.length > 1 ? "X" : ""} DISPONIBLE
              {prestation?.slots?.length > 1 ? "S" : ""}
            </div>
            <BlackBtn
              label="AJOUTER UN CRENEAU"
              styles={{ padding: "5px 20px" }}
              handleAction={handleCreateSlot}
            />
          </div>
          <Slotcontainer {...props} />
        </div>
      </PrestaContainer>
    </div>
  );
};

export default PrestationBlock;
