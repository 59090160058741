/* eslint-disable react/prop-types */
import React, { useState } from 'react'
import { CircularProgress } from '@mui/material'
import { updateSlot } from '_redux/Campaigns/reducers'
import { useDispatch } from 'react-redux'
import { enqueueSnackbar } from 'notistack'
import API from 'services/axios-config'
import './style.scss'
import { BlackBtn } from 'views/Admin/Shared/Forms/BlackBtn'
import TopContent from './TopContent'
import ModifyContent from './ModifyContent'
import { useRightModal } from 'views/Lists/Campaigns/rightModalContext'
import AlertDialog from 'components/DialogBox'
import { useSearchParams } from 'react-router-dom'

const ComponentSlotModification = ({ slot, onClose = () => {}, onCloseWithoutSaving = () => {} }) => {
  const [currentSlot, setCurrentSlot] = useState(null)
  const [alertOpen, setAlertOpen] = useState(false)
  const { replaceLastStackAction, removedLastStack } = useRightModal()
  const [disabledClick, setDisabledClick] = useState(false)
  const dispatch = useDispatch()
  const [searchParams] = useSearchParams()
  const campaignId = Number(searchParams.get('campaign_id'))
  const prestationId = Number(searchParams.get('prestation_id'))

  const handleModifySlot = () => {
    setDisabledClick(true)
    API.patch(`/slots/${slot.id}`, currentSlot)
      .then((res) => {
        onClose()
        dispatch(
          updateSlot({
            campaignId,
            prestationId,
            slotId: slot.id,
            updatedSlot: { ...slot, ...currentSlot, ...res.data }
          })
        )
      })
      .catch((err) => {
        enqueueSnackbar('Problème lors de la modification du slot : ' + err, {
          variant: 'error'
        })
      })
      .finally(() => {
        removedLastStack()
        setDisabledClick(false)
      })
  }

  function slotHasChange () {
    setAlertOpen(true)
  }

  function handleChangeSlot (value) {
    setCurrentSlot((prev) => ({ ...prev, ...value }))
    replaceLastStackAction(slotHasChange)
  }

  return (
    <div className="component-slot-modification">
      <AlertDialog
        open={alertOpen}
        setOpen={setAlertOpen}
        dialogType="abortSlotModify"
        action1={() => {
          removedLastStack();
          onCloseWithoutSaving();
        }}
      />
      <TopContent
        {...{ handleChangeSlot }}
        slot={{ ...slot, ...currentSlot }}
      />
      <ModifyContent
        {...{ handleChangeSlot }}
        slot={{ ...slot, ...currentSlot }}
      />
      <div className="save-slot">
        <div className="save-slot-button">
          {currentSlot && (
            <BlackBtn label="ENREGISTRER" handleAction={handleModifySlot} />
          )}
        </div>
      </div>
      {disabledClick && (
        <div className="loading-container">
          <CircularProgress size={60} />
        </div>
      )}
    </div>
  )
}

export default ComponentSlotModification
