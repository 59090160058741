import { enqueueSnackbar } from "notistack";
import API from "services/axios-config";

export async function getAllCampaigns(currentPage, per, searchParams) {
  const apiSearchParams = new URLSearchParams();
  searchParams.forEach((value, param) => {
    if (value) {
      apiSearchParams.append(param, value.split(","));
    }
  });
  try {
    return await API.get(
      `/campaigns?include_company=true&no_details=true&page=${currentPage + 1}&per=${per}${
        apiSearchParams?.toString() ? "&" + apiSearchParams.toString() : ""
      }`
    );
  } catch (err) {
    return enqueueSnackbar(
      "Une erreur lors de l'obtention des slots à eu lieu"
    );
  }
}

export async function getAllDevis(currentPage, per, searchParams) {
  const apiSearchParams = new URLSearchParams();
  searchParams.forEach((value, param) => {
    if (value) {
      apiSearchParams.append(param, value.split(","));
    }
  });
  try {
    return await API.get(
      `/devis?include_company=true&page=${currentPage + 1}&per=${per}${
        apiSearchParams?.toString() ? "&" + apiSearchParams.toString() : ""
      }`
    );
  } catch (err) {
    return enqueueSnackbar(
      "Une erreur lors de l'obtention des slots à eu lieu"
    );
  }
}

export async function getDevisById(devisId) {
  try {
    return await API.get(`/devis/${devisId}`);
  } catch (err) {
    return enqueueSnackbar(
      "Une erreur lors de l'obtention des slots à eu lieu"
    );
  }
}

export async function refuseDevisById(devisId, description) {
  try {
    return await API.put(`/devis/${devisId}/refuse_devis`, {
      refuse_description: description,
    });
  } catch (err) {
    return enqueueSnackbar(
      "Une erreur lors de l'obtention des slots à eu lieu"
    );
  }
}

export function updateFiltersParams(selectedFilters, setFiltersParams) {
  const filterFields = [
    { key: "status", isArray: true },
    { key: "prestation", isArray: true },
    { key: "date", isArray: false },
    { key: "responsable_com", isArray: false },
    { key: "responsable_op", isArray: false },
  ];

  filterFields.forEach(({ key, isArray }) => {
    const value = selectedFilters[key];
    const hasValue = isArray ? value?.length > 0 : !!value;

    if (hasValue) {
      setFiltersParams((prev) => ({ ...prev, [key]: value }));
    } else {
      setFiltersParams((prev) => {
        const { [key]: removed, ...rest } = prev;
        return rest;
      });
    }
  });
}
