/* eslint-disable */
import React, { useState } from 'react';
import { Box, Modal, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { SelectInput } from 'components/GlobalInputs/Select';
import BtnComponent from 'components/GlobalInputs/Btn';
import { addInvoiceDetail, updateSmInvoice } from './functions';
import { enqueueSnackbar } from 'notistack';


const ValidatedMissions = ({ open, onClose, missions, setIsChanged, rows, setRows, smInvoiceId, updateTotals, setNewLineIndex }) => {
    const [selectedMission, setSelectedMission] = useState(null);

    const handleValidate = () => {
        const payload = {
            mission_id: selectedMission.id,
            sm_invoice_id: smInvoiceId,
        };
        addInvoiceDetail(payload)
            .then((res) => {
                const updatedRows = [
                    ...rows,
                    ...res.data.created_details.map(detail => ({
                        id: detail.id,
                        description: detail.description,
                        type: 'SERVICES',
                        date: detail.date,
                        qty: detail.quantity,
                        unit: detail.unit,
                        price: detail.unit_price,
                        tva: detail.tva,
                        amount: detail.quantity * detail.unit_price * (1 + detail.tva / 100.0)
                    }))
                ];
                setRows(updatedRows);
                updateTotals(updatedRows);
                onClose();
                updateSmInvoice(smInvoiceId, {
                    invoice_details: updatedRows,
                })
            })
            .catch((err) => enqueueSnackbar(err.message, { variant: "error" }))
    }
    const handleAddLine = () => {
        const newRow = {
            description: '',
            type: 'SERVICES',
            date: '',
            qty: 0,
            unit: '',
            price: 0,
            tva: 0,
            amount: 0,
            validated: true, 
            added_by: 'admin'
        };
        setRows([...rows, newRow]);
        updateTotals([...rows, newRow]);
        setIsChanged(true);
        setNewLineIndex(rows.length)
        onClose();
    }
    return (
        <Modal
            open={open}
            onClose={onClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <Typography id="modal-modal-title" variant="h6" component="h2" style={textStyle}>
                        Choisis parmi les missions validées du SM pour l’ajouter à la facture
                    </Typography>
                    <CloseIcon sx={{ color: 'black' }} onClick={onClose} />
                </div>
                <Typography id="modal-modal-title" style={contentText}>
                    Tu ne peux pas choisir une missions qui n’est pas terminée / validée pour l’ajouter à une facture.
                    Si tu as besoin de généré une nouvelle mission pour cette facture, crée la dans la campagne correspondante, valide la, sélectionne là ici, et modifie ensuite les champs.
                </Typography>
                <div style={{ display: 'flex', flexDirection: 'column', gap: 20, width: '-webkit-fill-available' }}>
                    <SelectInput label="MISSIONS VALIDÉES" variant="rounded" nullable keys={['campaign_name', 'slot_title', 'presta_type']} items={missions} onChange={(e) => setSelectedMission(e)} withAddLine
                        addLineClick={handleAddLine} />
                </div>
                <div style={{ display: 'flex', justifyContent: 'space-around', marginTop: 50 }}>
                    <BtnComponent text={'CONFIRMER'} onClick={handleValidate} variant="rounded" style={buttonStyle} />
                    <BtnComponent text={'ANNULER'} onClick={() => onClose()} variant="rounded" color='red' style={buttonStyle} />
                </div>
            </Box>
        </Modal>
    );
};

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '50%',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    maxHeight: '80vh', overflowY: 'auto'
};

const textStyle = {
    color: 'black',
    fontWeight: '800',
    fontSize: '20px',
}
const contentText = {
    marginTop: 20,
    marginBottom: 20,
    textAlign: 'left',
    color: 'black',
    fontWeight: '800',
    fontSize: '14px',
}
const buttonStyle = {
    borderRaduis: 10,
    width: 130,
    fontWeight: 800,
    fontSize: 12,
}
export default ValidatedMissions;
