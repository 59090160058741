/* eslint-disable react/prop-types */
/* eslint-disable camelcase */
import React, { useCallback, useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import './style.scss'
import '../styles.scss'
import HeaderList from '../Headers'

import ViewsListTable from 'components/Table/ViewsListTable'
import { enqueueSnackbar } from 'notistack'
import { getAllCampaigns, getAllDevis, updateFiltersParams } from './functions'
import CampaignCardView from '../../Campaign/CampaignCard'
import SlotCardView from '../../Slot/SlotCard'
import { useDispatch, useSelector } from 'react-redux'

import { setCampaignsList, updateCampaignList } from '_redux/Campaigns/reducers'
import { Filter } from './filter'
import API from 'services/axios-config'
import { RightModalProvider } from './rightModalContext'
import { SlidingModal } from './devisModal'
import { Modal } from '@mui/material'
import CampaignViewMap from 'views/Cartes/CampaignViewMap'
import FilterComponent from 'components/GlobalInputs/Filters'
import RightAction from './rightAction'
import LeftComponent from './leftComponent'
import { filters } from './constants'
import { CampaignName, HandleMap, VisuStatus } from './campaignsComponent'
import { createData } from 'components/Table/ViewsListTable/function'

const CampaignList = () => {
  const [fetching, setFetching] = useState(true)
  const [searchParams, setSearchParams] = useSearchParams()
  const mapOpen = searchParams.get('maps')
  const campaignOpen = searchParams.get('campaign_id')
  const devisOpen = searchParams.get('devis_id')
  const [currentPage, setCurrentPage] = useState(0)
  const [per, setPer] = useState(25)
  const [maxPage, setMaxPage] = useState(1)
  const dispatch = useDispatch()
  const [isDevis, setIsDevis] = useState(false)
  const campaignRawList = useSelector(state => state.campaignsList.list)

  /* FOR FILTERS */
  const [filtersParams, setFiltersParams] = useState({})
  const [selectedFilters, setSelectedFilters] = useState({
    status: [],
    date: null,
    prestation: [],
    responsable_com: null,
    responsable_op: null,
  });

  useEffect(() => {
    updateFiltersParams(selectedFilters, setFiltersParams);
  }, [selectedFilters])

  useEffect(() => {
    if (searchParams.get('devis_id')) return;
    if (searchParams.get('devis')) {
      setIsDevis(true)
    } else {
      setIsDevis(false)
      const newSearchParams = new URLSearchParams()
      if (searchParams?.get('search')) newSearchParams.set('search', searchParams.get('search'))
      if (searchParams?.get('status')) newSearchParams.set('status', searchParams.get('status'))
      if (searchParams?.get('future')) newSearchParams.set('future', searchParams.get('future'))
      if (searchParams?.get('past')) newSearchParams.set('past', searchParams.get('past'))
      /* filters params */
      Object.entries(filtersParams).forEach(([key, value]) => {
        newSearchParams.set(key, value)
      });
      /* end filters params */
      setCurrentPage(0)
      if (!searchParams.has('campaign_id')) setSearchParams(newSearchParams)
    }
  }, [searchParams, filtersParams])

  async function handleActionCampaign(action) {
    switch (action?.type) {
      case 'row-action': {
        const campaign_id = action?.row?.find(_item => _item?.id === 'id').value
        const newParams = new URLSearchParams(searchParams)
        newParams.set('campaign_id', campaign_id)
        setSearchParams(newParams)
        break
      }
      default:
        enqueueSnackbar('Action non connecté')
        break
    }
  }

  async function handleActionDevis(action) {
    switch (action?.type) {
      case 'row-action': {
        const devisId = action?.row?.find(_item => _item?.id === 'id').value
        setSearchParams(`devis_id=${devisId}`)
        break
      }
      default:
        enqueueSnackbar('Action non connecté')
        break
    }
  }

  function handleChangeData(data) {
    setMaxPage(data?.maxPage)
    dispatch(updateCampaignList(data.campaigns_id))
    dispatch(setCampaignsList(data.campaigns.map((campaignData) => ({ campaign: campaignData }))))
  }

  function handleChangeDevisData(data) {
    setCurrentPage(0);
    setMaxPage(data?.maxPage)
    dispatch(updateCampaignList(data.devis_ids))
    dispatch(setCampaignsList(data.devis.map((devisData) => ({ campaign: devisData }))))
  }

  useEffect(() => {
    if (searchParams.get('campaign_id') !== null || searchParams.get('devis_id') !== null) return;
    if (searchParams.get('devis') || searchParams.get('devis_id')) {
      setFetching(true)
      getAllDevis(currentPage, per, searchParams)
        .then(res => {
          handleChangeDevisData(res?.data)
        })
        .catch(err => enqueueSnackbar(err, { variant: 'error' }))
        .finally(_ => setFetching(false))
    } else {
      setFetching(true)
      getAllCampaigns(currentPage, per, searchParams)
        .then(res => {
          handleChangeData(res?.data)
        })
        .catch(err => enqueueSnackbar(err, { variant: 'error' }))
        .finally(_ => setFetching(false))
    }
  }, [currentPage, per, searchParams])

  const handleCloseDevisModal = () => {
    setSearchParams((prevParams) => {
      prevParams.delete('devis_id');
      prevParams.set('devis', 'true');
      return prevParams;
    });
  };

  const createCampaignFromDevis = (devisId) => {
    API.post('campaigns', { from_devis_id: devisId })
      .then(res => {
        dispatch(setCampaignsList([{ campaign: res.data }]))
        setSearchParams(`campaign_id=${res?.data?.id}&fromDevis=true`)
      })
      .catch(err => enqueueSnackbar('L\'ajout de campagne à échouée', err))
  }

  function handleMap(e, id) {
    e.stopPropagation()
    setSearchParams(`campaign_id=${id}&maps=true`)
  }

  const devisItems = [
    { id: 'id', exist: true },
    { id: 'campaign_name', column_name: 'NOM', maxLength: 20, exist: true, isVisible: true },
    { id: 'company_name', column_name: 'CLIENT', sub_items: ['company', 'company_name'], maxLength: 20, isVisible: true },
    { id: 'date_start', exist: true, format: 'date', column_name: 'DATES', isVisible: true },
    { id: 'status', width: 400, column_name: 'STATUT', exist: true, isVisible: true, component: <VisuStatus campaignsList={campaignRawList} isDevis /> },
  ]

  const items = [
    { id: 'id', exist: true },
    { id: 'campaign_name', column_name: 'CAMPAGNE', maxLength: 20, exist: true, isVisible: true, component: <CampaignName campaignsList={campaignRawList} /> },
    { id: 'is_alert', column_name: 'IS ALERT', exist: true, isVisible: false },
    { id: 'company_name', column_name: 'CLIENT', sub_items: ['company', 'company_name'], maxLength: 20, isVisible: true },
    { id: 'previsionnal_start_date', exist: true, format: 'date', column_name: 'DATES', isVisible: true },
    { id: 'status', width: 400, column_name: 'STATUT', exist: true, align: 'center', isVisible: true, component: <VisuStatus campaignsList={campaignRawList} /> },
    { id: 'chat', column_name: 'CARTE', type: 'picture', align: 'center', isVisible: true, isSVG: true, component: <HandleMap action={handleMap} /> }
  ]

  const columns = isDevis ? devisItems : items

  const formatedCampaignList = useCallback(() => {
    if (campaignRawList?.some(item => item?.campaign) || campaignRawList.length === 0) {
      return createData(campaignRawList.map((item) => item?.campaign), isDevis ? devisItems : items)
    } else {
      return []
    }
  }, [campaignRawList, isDevis])

  return (
    <RightModalProvider>
      <div className="list-container">
        <div className="list-content">
          <HeaderList leftComponent={<LeftComponent title={isDevis ? 'DEVIS' : 'CAMPAGNES'} isDevis={isDevis} />} rightComponent={<RightAction />} />
          {!isDevis && <div className="filter-component-compaigns-container">
            <FilterComponent filters={filters} updateSelectedFilters={setSelectedFilters} />
          </div>}
          <ViewsListTable {...{ fetching, setCurrentPage, currentPage, setPer, per, setMaxPage, maxPage }} handleActions={(action) => {
            if (isDevis) handleActionDevis(action)
            else handleActionCampaign(action);
          }} filter={<Filter />} columns={columns} list={formatedCampaignList()} />
        </div>
        {campaignOpen && !mapOpen && <CampaignCardView />}
        {devisOpen && <SlidingModal isOpen={devisOpen} onClose={handleCloseDevisModal} devisId={devisOpen} onCreateCampaign={createCampaignFromDevis} />}
        <SlotCardView />
        <Modal open={mapOpen}><CampaignViewMap /></Modal>
      </div>
    </RightModalProvider>
  )
}
export default CampaignList
