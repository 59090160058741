/* eslint-disable react/prop-types */
import React from 'react'
import { CircularProgress, TableBody, TableCell, TableRow } from '@mui/material'
import Row from './row'

const BodyTable = (props) => {
  const { list, columns, handleActions, closeAll, setCloseAll, fetching } = props

  return (
      <TableBody style={{ overflow: 'scroll' }}>
        {fetching &&
          <TableRow>
            <TableCell
              colSpan={list[0]?.length || 7}
              style={{
                textAlign: 'center',
                padding: '20px',
                border: 'none'
              }}
            >
              <CircularProgress />
            </TableCell>
          </TableRow>
        }
        {list && list
          .map((row, index) => {
            return <Row key={`current-row-${index}`} {...{ columns, row, handleActions, closeAll, setCloseAll }}/>
          })}
      </TableBody>
  )
}

export default BodyTable
