/* eslint-disable react/prop-types */
import React, { useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'
import { enqueueSnackbar } from 'notistack'
import API from 'services/axios-config'
import { setCampaignsList } from '_redux/Campaigns/reducers'
import { useDispatch } from 'react-redux'
import PrestationsView from './PrestationsView'
import TopPageContent from './TopPageContent'
import './style.scss'

const CampaignCardView = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  const dispatch = useDispatch()

  useEffect(() => {
    API.get(`campaigns/${searchParams.get('campaign_id')}/campaign_informations`)
      .then(res => {
        dispatch(setCampaignsList([res.data]))
      })
      .catch(err => {
        enqueueSnackbar(err, { variant: 'error', autoHideDuration: 3000 })
      })
  }, [])

  const onClose = () => {
    searchParams.delete('campaign_id');
    searchParams.delete('create');
    setSearchParams(searchParams);
  }

  return (
        <div className="campaign-card-container" onMouseDown={onClose}>
            <div className="campaign-card-content" onMouseDown={e => e.stopPropagation()}>
                <TopPageContent />
                <PrestationsView />
            </div>
        </div>
  )
}

export default CampaignCardView
