/* eslint-disable react/prop-types */
import React, { useState } from 'react'
import { Box, Button, CircularProgress, Modal, Popover } from '@mui/material'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import moment from 'moment'
import { BlackBtn } from 'views/Admin/Shared/Forms/BlackBtn'
import ComponentGaleriePhoto from '../../ComponentGaleriePhoto'
import { useRightModal } from 'views/Lists/Campaigns/rightModalContext'
import './style.scss'
import { useNavigate, useSearchParams } from 'react-router-dom'
import API from 'services/axios-config'
import { enqueueSnackbar } from 'notistack'
import { useDispatch } from 'react-redux'
import { afterMissionValidate, updateMission } from '_redux/Campaigns/reducers'
import pen from 'assets/pen.png'
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'

const InfosMission = (props) => {
  const { mission } = props
  const [anchorEl, setAnchorEl] = useState(null)
  const [disabledClick, setDisabledClick] = useState(false)
  const color = styleMission()
  const open = Boolean(anchorEl)
  const id = open ? 'simple-popover-infos-mission' : undefined
  const { addToStack } = useRightModal()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [searchParams] = useSearchParams()
  const campaignId = Number(searchParams.get('campaign_id'))
  const prestationId = Number(searchParams.get('prestation_id'))
  const slotId = Number(searchParams.get('slot_id'))
  const [customCheck, setCustomCheck] = useState({ open: null, checkType: "" })
  const [customCheckInCheckOut, setCustomCheckInCheckOut] = useState({
    check_in: mission.check_in,
    check_out: mission.check_out,
  })
  console.log(customCheck, customCheckInCheckOut)

  function styleMission () {
    let color
    if (['Terminée'].includes(mission?.status)) { color = '#07C025' } else if (['En cours'].includes(mission?.status)) { color = '#60EEDD' } else if (['Validé', 'En attente de lancement', 'En attente de confirmation'].includes(mission?.status)) { color = '#60EEDD' } else { color = '#FF0000' }
    return color
  }

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleChangeMission = (state) => {
    setDisabledClick(true)
    API.patch(`/missions/${mission?.id}`, { id: mission?.id, status: state.status })
      .then(res => {
        dispatch(updateMission({ campaignId, prestationId, slotId, missionId: mission?.id, updatedMission: { ...res?.data } }))
        if (res?.data?.status === 'Validé') {
          dispatch(afterMissionValidate({ campaignId, prestationId, slotId, missionId: mission?.id }))
        }
      })
      .catch(err => {
        enqueueSnackbar('Patch mission : ' + err, { variant: 'error' })
      })
      .finally(() => {
        handleClose()
        setDisabledClick(false)
      })
  }

  const changeCheckTime = () => {
    API.patch(`/missions/${mission?.id}`, { id: mission?.id, ...customCheckInCheckOut })
      .then(res => {
        dispatch(updateMission({ campaignId, prestationId, slotId, missionId: mission?.id, updatedMission: { ...res?.data } }))
        if (res?.data?.status === 'Validé') {
          dispatch(afterMissionValidate({ campaignId, prestationId, slotId, missionId: mission?.id }))
        }
        setCustomCheck({ open: null, checkType: "" })
      })
      .catch(err => {
        enqueueSnackbar('Patch mission change time : ' + err, { variant: 'error' })
      })
  }

  function handleGoTo(url) {
    window.open(url, '_blank');
  }

  const handleSendCheckIn = () => {
    API.get(`/missions/${mission?.id}/force_sm_check_in`)
    .then(_ => {
      enqueueSnackbar("Notification vers SM pour forcer le check-in est envoyer avec success.", { variant: "success" });
    })
    .catch((err) => {
      console.error(err);
      enqueueSnackbar("Erreur lors de l'envoie de notification pour forcer le check-in : ", err, { variant: "error" });
    });
  }

  return (
    <div className='infos-missionCandidate-container'>
      <div className='missionCandidate-container'>
        <div style={{ position: "absolute", marginBottom: 50, fontSize: 8 }}>{mission.id}</div>
        <div onClick={() => navigate(`/admin/street-marketeurs?marketer_id=${mission.user_id}`)} style={{ display: "inline", cursor: "pointer" }}>{mission.user_name}</div>
        <Button aria-describedby={id} variant="contained" onClick={handleClick} style={{ color, background: color + '50' }} className='missionCandidate-state-container' disabled={disabledClick}>
          <div className='container-flex'>
            <div>
            {disabledClick && <div><CircularProgress style={{ position: 'absolute', width: '30px', height: '30px', top: '15%', left: '40%' }} /></div> }
              <span style={{ background: color }} className='missionCandidate-state-dot' ></span>
              <div style={{ filter: disabledClick ? 'blur(4px)' : '', display: 'inline' }}>{mission.status}</div>
            </div>
            <ArrowDropDownIcon />
          </div>
        </Button>
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center'
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center'
          }}
        >
          <div className='popover-missionCandidate-container' >
            {
              ['Terminée', 'En cours', 'Validé', 'En attente de lancement', 'En attente de confirmation', 'En attente de validation', 'Refusé'].map((state, index) => {
                return <div
                  key={index}
                  className='popover-item'
                  style={{ color: state === mission.status ? color : 'black', pointerEvents: state === mission.status || disabledClick ? 'none' : '' }}
                  onClick={disabledClick ? null : () => handleChangeMission({ status: state })}
                >
                  {state}
                </div>
              })
            }
          </div>
        </Popover>
      </div>
      <div className='missionInfos-container'>
        <div className='missionInfos-check'>
          <div style={{ alignContent: 'center' }}>Check-in:</div>
          <div style={{ color: mission.check_in ? '' : '#FF0000', alignContent: "center" }}>{mission.check_in ? moment(mission.check_in).format('LLLL') : 'Non défini' }</div>
          <img src={pen} alt='Pen Image' className='slot-pen' onClick={() => { setCustomCheck({ open: mission.id, checkType: "check_in" }); setCustomCheckInCheckOut(prev => ({ ...prev, check_in: moment(mission.check_in ? mission.check_in : new Date()).format('YYYY-MM-DDTHH:mm:ssZ') })) }} />
          <div style={{ alignContent: 'center' }}>Check-out:</div>
          <div style={{ color: mission.check_out ? '' : '#FF0000', alignContent: "center" }}>{mission.check_out ? moment(mission.check_out).format('LLLL') : 'Non défini' }</div>
          <img src={pen} alt='Pen Image' className='slot-pen' onClick={() => { setCustomCheck({ open: mission.id, checkType: "check_out" }); setCustomCheckInCheckOut(prev => ({ ...prev, check_out: moment(mission.check_out ? mission.check_out : new Date()).format('YYYY-MM-DDTHH:mm:ssZ') })) }} />
        </div>
        <div className='missionInfos-buttons'>
          {["En cours", "Terminée"].includes(mission.status) && <>
            <BlackBtn label='VOIR LES PHOTOS' handleAction={() => addToStack({ component: <ComponentGaleriePhoto missionId={mission.id} /> })} />
            <BlackBtn label='VALIDATION DE MISSION' handleAction={() => handleGoTo(`/admin/waiting_validation?mission_id=${mission.id}`)} />
          </>}
          {["Validé", "En attente de lancement"].includes(mission.status) && <>
            <BlackBtn label='LANCER LE CHECK-IN' handleAction={handleSendCheckIn} styles={{ margin: 'auto' }} />
          </>}
        </div>
      </div>
      <Modal
        open={customCheck.open}
        onClose={() => setCustomCheck({ open: null, checkType: "" })}
        aria-labelledby="modal-changeTime"
        aria-describedby="modal-change-check-time"
      >
        <Box sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          bgcolor: 'white',
          boxShadow: 24,
          p: 4,
          borderRadius: 1
        }}>
          <h3 style={{ marginTop: 0 }}>Modifier {customCheck.checkType === 'check_in' ? "le check in" : "le check out"}</h3>
          <LocalizationProvider adapterLocale="fr" dateAdapter={AdapterMoment}>
          <DateTimePicker
            defaultValue={mission[customCheck.checkType] ? moment(mission[customCheck.checkType]) : moment()}
            onChange={(newValue) => {
              setCustomCheckInCheckOut(prev => ({
                ...prev,
                [customCheck.checkType]: moment(newValue.toDate()).format('YYYY-MM-DDTHH:mm:ssZ')
              }))
            }}
          />
          </LocalizationProvider>
          <div>
            <BlackBtn label='VALIDER' handleAction={changeCheckTime} styles={{ margin: '20px', justifyContent: 'center' }} />
            <BlackBtn label='ANNULER' handleAction={() => setCustomCheck({ open: null, checkType: "" })} styles={{ margin: '20px', marginBotom: 0, justifyContent: 'center', color: 'red' }} />
          </div>
        </Box>
      </Modal>
    </div>
  )
}

export default InfosMission
