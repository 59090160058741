import { BlackBtn } from "views/Admin/Shared/Forms/BlackBtn";
import API from "services/axios-config";
import { useSearchParams } from "react-router-dom";
import { enqueueSnackbar } from "notistack";
import ComponentCampaignModification from "views/Slot/SlotCard/ComponentSlotCardView/ComponentCampaignModification";
import { useRightModal } from "../rightModalContext";
import { useDispatch } from "react-redux";
import { setCampaignsList } from "_redux/Campaigns/reducers";

const RightAction = (props) => {
  const [, setSearchParams] = useSearchParams();
  const { addToStack } = useRightModal();
  const dispatch = useDispatch()

  function handleClick() {
    API.post("campaigns")
      .then((res) => {
        dispatch(setCampaignsList([{ campaign: res.data }]))
        setSearchParams(`campaign_id=${res?.data?.id}`);
        addToStack({
          component: (
            <ComponentCampaignModification
              {...props}
              campaignData={{ campaign: res.data }}
            />
          ),
        });
      })
      .catch((err) => enqueueSnackbar("L'ajout de campagne à échouée", err));
  }
  return (
    <BlackBtn handleAction={handleClick} reverse label="NOUVELLE CAMPAGNE" />
  );
};

export default RightAction;
